import { StrategyType } from "../../../types/strategy";
import { formatStrategy } from "../../../utils/amount";
import {
  addYourChangesSign,
  displayStrategyAmount,
} from "../../../utils/component/overview";

type OverviewYourChangesProps = {
  strategyStatus: string;
  isTabletAndMore: boolean;
  overview: StrategyType;
  currentUserAmountChange?: number;
};

function OverviewYourChanges({
  strategyStatus,
  isTabletAndMore,
  overview,
  currentUserAmountChange = 0,
}: OverviewYourChangesProps) {

  return (
    <div className="overview-board__total overview-board__total--your-changes">
      <p className="overview-board__amount">
        {addYourChangesSign(
          isTabletAndMore,
          strategyStatus,
          formatStrategy(currentUserAmountChange).amount,
          formatStrategy(currentUserAmountChange).amount
        )}
        <span className="overview-board__number">
          {displayStrategyAmount(
            "initial",
            strategyStatus,
            isTabletAndMore,
            currentUserAmountChange,
            overview.currency,
            currentUserAmountChange
          )}
        </span>
      </p>
      <span className="overview-board__text">Your changes</span>
    </div>
  );
}

export default OverviewYourChanges;
