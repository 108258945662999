import { StrategyStatus } from "../../../const";
import { StrategyType } from "../../../types/strategy";
import {
  deleteMinusIfNegative,
  formatStrategy,
  formatStrategyTrending,
  localAmount,
} from "../../../utils/amount";
import {
  addBoardSign,
  displayStrategyAmount,
} from "../../../utils/component/overview";
import { showDateWithShortYear } from "../../../utils/datetime";

type OverviewLastMonthProps = {
  strategyStatus: string;
  isTabletAndMore: boolean;
  overview: StrategyType;
};

export default function OverviewLastMonth({
  strategyStatus,
  isTabletAndMore,
  overview,
}: OverviewLastMonthProps): JSX.Element {
  const currency = overview.currency;
  const startTimestamp = overview.start_timestamp;
  const percentProfit = overview.profit_from_start_percent;
  const avg_monthly_return = overview.avg_monthly_return;

  const showText = () => {
    if (percentProfit !== null) {
      const formattedAmount = deleteMinusIfNegative(
        formatStrategyTrending(percentProfit).amount
      );
      const shortFormat = formatStrategyTrending(percentProfit).short;

      return `${localAmount(formattedAmount)}${shortFormat}%`;
    }
  };

  return avg_monthly_return !== null &&
    percentProfit !== null &&
    startTimestamp !== null ? (
    <div className="overview-board__total overview-board__total--last-month">
      <p className="overview-board__amount">
        {strategyStatus === StrategyStatus.Started
          ? addBoardSign(
              strategyStatus,
              formatStrategy(percentProfit).amount,
              formatStrategy(percentProfit).amount
            )
          : addBoardSign(
              strategyStatus,
              formatStrategy(avg_monthly_return).amount,
              formatStrategy(avg_monthly_return).amount
            )}
        <span className="overview-board__number">
          {strategyStatus === StrategyStatus.Started
            ? showText()
            : displayStrategyAmount(
                "last-month",
                strategyStatus,
                isTabletAndMore,
                avg_monthly_return,
                currency,
                avg_monthly_return,
                startTimestamp
              )}
        </span>
      </p>
      <span className="overview-board__text">
        {strategyStatus === StrategyStatus.Started
          ? `Since ${showDateWithShortYear(startTimestamp)}`
          : "Average monthly"}
      </span>
    </div>
  ) : (
    <></>
  );
}
