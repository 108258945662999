import PricingButtonGroup from "./pricing-button-group";

type PricingSummarySectionProps = {
  isMobile: boolean;
};

function PricingSummarySection({ isMobile }: PricingSummarySectionProps) {
  return (
    <section className="landing__item--pricing-fees pricing-block">
      <div className="pricing__main-description-block">
        <div className="pricing__heading">
          <h1 className="landing__heading">
            <span className="landing__heading-large">
              Try it for 30 days and pay for results only
            </span>
          </h1>
        </div>
        <p className="pricing__main-description">
          Our pricing is based on principles of transparency and mutual benefit.
          Get access to our trading software for a small fee and only pay the
          service fee when you earn — your success = our success.
        </p>
        {!isMobile ? <PricingButtonGroup /> : null}
      </div>
      <div className="pricing__img" />
      {isMobile ? <PricingButtonGroup /> : null}
    </section>
  );
}

export default PricingSummarySection;
