export default function FaqAccountManagmentIcon(): JSX.Element {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.283 9.65625C16.3719 8.97813 15.2541 8.5625 14.0312 8.5625C12.5808 8.5625 11.1898 9.13867 10.1643 10.1643C9.13867 11.1898 8.5625 12.5808 8.5625 14.0312C8.5625 15.2541 8.97813 16.3719 9.65625 17.283V23.047L8.61719 24.085C8.41208 24.29 8.13393 24.4052 7.84391 24.4052C7.55388 24.4052 7.27573 24.29 7.07063 24.085L3.9775 20.9919C3.77245 20.7868 3.65726 20.5086 3.65726 20.2186C3.65726 19.9286 3.77245 19.6504 3.9775 19.4453L5.15437 18.2673C5.00672 17.9567 4.87437 17.6384 4.75844 17.3125H3.09375C2.80367 17.3125 2.52547 17.1973 2.32035 16.9921C2.11523 16.787 2 16.5088 2 16.2188V11.8438C2 11.5537 2.11523 11.2755 2.32035 11.0704C2.52547 10.8652 2.80367 10.75 3.09375 10.75H4.75844C4.87378 10.4251 5.00593 10.1064 5.15437 9.79516L3.9775 8.61719C3.77245 8.41208 3.65726 8.13393 3.65726 7.84391C3.65726 7.55388 3.77245 7.27573 3.9775 7.07063L7.07063 3.9775C7.27573 3.77245 7.55388 3.65726 7.84391 3.65726C8.13393 3.65726 8.41208 3.77245 8.61719 3.9775L9.79516 5.15437C10.1064 5.00593 10.4251 4.87378 10.75 4.75844V3.09375C10.75 2.80367 10.8652 2.52547 11.0704 2.32035C11.2755 2.11523 11.5537 2 11.8438 2H16.2188C16.5088 2 16.787 2.11523 16.9921 2.32035C17.1973 2.52547 17.3125 2.80367 17.3125 3.09375V4.75844C17.6374 4.87378 17.9561 5.00593 18.2673 5.15437L19.4453 3.9775C19.6504 3.77279 19.9283 3.65781 20.218 3.65781C20.5078 3.65781 20.7857 3.77279 20.9908 3.9775L24.0861 7.07063C24.2911 7.27573 24.4063 7.55388 24.4063 7.84391C24.4063 8.13393 24.2911 8.41208 24.0861 8.61719L23.047 9.65625H17.283Z"
        fill="#2563EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.625 18.4062V24.9688H10.75V18.4062H32.625ZM28.4102 20.7539C28.3076 20.8565 28.25 20.9956 28.25 21.1406V22.2344C28.25 22.3794 28.3076 22.5185 28.4102 22.6211C28.5127 22.7236 28.6518 22.7812 28.7969 22.7812C28.9419 22.7812 29.081 22.7236 29.1836 22.6211C29.2861 22.5185 29.3438 22.3794 29.3438 22.2344V21.1406C29.3438 20.9956 29.2861 20.8565 29.1836 20.7539C29.081 20.6514 28.9419 20.5938 28.7969 20.5938C28.6518 20.5938 28.5127 20.6514 28.4102 20.7539ZM26.2227 20.7539C26.1201 20.8565 26.0625 20.9956 26.0625 21.1406V22.2344C26.0625 22.3794 26.1201 22.5185 26.2227 22.6211C26.3252 22.7236 26.4643 22.7812 26.6094 22.7812C26.7544 22.7812 26.8935 22.7236 26.9961 22.6211C27.0986 22.5185 27.1562 22.3794 27.1562 22.2344V21.1406C27.1562 20.9956 27.0986 20.8565 26.9961 20.7539C26.8935 20.6514 26.7544 20.5938 26.6094 20.5938C26.4643 20.5938 26.3252 20.6514 26.2227 20.7539ZM24.0352 20.7539C23.9326 20.8565 23.875 20.9956 23.875 21.1406V22.2344C23.875 22.3794 23.9326 22.5185 24.0352 22.6211C24.1377 22.7236 24.2768 22.7812 24.4219 22.7812C24.5669 22.7812 24.706 22.7236 24.8086 22.6211C24.9111 22.5185 24.9688 22.3794 24.9688 22.2344V21.1406C24.9688 20.9956 24.9111 20.8565 24.8086 20.7539C24.706 20.6514 24.5669 20.5938 24.4219 20.5938C24.2768 20.5938 24.1377 20.6514 24.0352 20.7539ZM21.8477 20.7539C21.7451 20.8565 21.6875 20.9956 21.6875 21.1406V22.2344C21.6875 22.3794 21.7451 22.5185 21.8477 22.6211C21.9502 22.7236 22.0893 22.7812 22.2344 22.7812C22.3794 22.7812 22.5185 22.7236 22.6211 22.6211C22.7236 22.5185 22.7812 22.3794 22.7812 22.2344V21.1406C22.7812 20.9956 22.7236 20.8565 22.6211 20.7539C22.5185 20.6514 22.3794 20.5938 22.2344 20.5938C22.0893 20.5938 21.9502 20.6514 21.8477 20.7539Z"
        fill="#2563EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0312 10.75H29.3438C31.1528 10.75 32.625 12.2222 32.625 14.0312V17.3125H10.75V14.0312C10.75 12.2222 12.2222 10.75 14.0312 10.75ZM22.6211 14.9648C22.7236 14.8623 22.7812 14.7232 22.7812 14.5781V13.4844C22.7812 13.3393 22.7236 13.2002 22.6211 13.0977C22.5185 12.9951 22.3794 12.9375 22.2344 12.9375C22.0893 12.9375 21.9502 12.9951 21.8477 13.0977C21.7451 13.2002 21.6875 13.3393 21.6875 13.4844V14.5781C21.6875 14.7232 21.7451 14.8623 21.8477 14.9648C21.9502 15.0674 22.0893 15.125 22.2344 15.125C22.3794 15.125 22.5185 15.0674 22.6211 14.9648ZM24.8086 14.9648C24.9111 14.8623 24.9688 14.7232 24.9688 14.5781V13.4844C24.9688 13.3393 24.9111 13.2002 24.8086 13.0977C24.706 12.9951 24.5669 12.9375 24.4219 12.9375C24.2768 12.9375 24.1377 12.9951 24.0352 13.0977C23.9326 13.2002 23.875 13.3393 23.875 13.4844V14.5781C23.875 14.7232 23.9326 14.8623 24.0352 14.9648C24.1377 15.0674 24.2768 15.125 24.4219 15.125C24.5669 15.125 24.706 15.0674 24.8086 14.9648ZM26.9961 14.9648C27.0986 14.8623 27.1562 14.7232 27.1562 14.5781V13.4844C27.1562 13.3393 27.0986 13.2002 26.9961 13.0977C26.8935 12.9951 26.7544 12.9375 26.6094 12.9375C26.4643 12.9375 26.3252 12.9951 26.2227 13.0977C26.1201 13.2002 26.0625 13.3393 26.0625 13.4844V14.5781C26.0625 14.7232 26.1201 14.8623 26.2227 14.9648C26.3252 15.0674 26.4643 15.125 26.6094 15.125C26.7544 15.125 26.8935 15.0674 26.9961 14.9648ZM29.1836 14.9648C29.2861 14.8623 29.3438 14.7232 29.3438 14.5781V13.4844C29.3438 13.3393 29.2861 13.2002 29.1836 13.0977C29.081 12.9951 28.9419 12.9375 28.7969 12.9375C28.6518 12.9375 28.5127 12.9951 28.4102 13.0977C28.3076 13.2002 28.25 13.3393 28.25 13.4844V14.5781C28.25 14.7232 28.3076 14.8623 28.4102 14.9648C28.5127 15.0674 28.6518 15.125 28.7969 15.125C28.9419 15.125 29.081 15.0674 29.1836 14.9648Z"
        fill="#2563EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0312 32.625C12.2222 32.625 10.75 31.1528 10.75 29.3438V26.0625H32.625V29.3438C32.625 31.1528 31.1528 32.625 29.3438 32.625H14.0312ZM28.4102 28.4102C28.3076 28.5127 28.25 28.6518 28.25 28.7969V29.8906C28.25 30.0357 28.3076 30.1748 28.4102 30.2773C28.5127 30.3799 28.6518 30.4375 28.7969 30.4375C28.9419 30.4375 29.081 30.3799 29.1836 30.2773C29.2861 30.1748 29.3438 30.0357 29.3438 29.8906V28.7969C29.3438 28.6518 29.2861 28.5127 29.1836 28.4102C29.081 28.3076 28.9419 28.25 28.7969 28.25C28.6518 28.25 28.5127 28.3076 28.4102 28.4102ZM26.2227 28.4102C26.1201 28.5127 26.0625 28.6518 26.0625 28.7969V29.8906C26.0625 30.0357 26.1201 30.1748 26.2227 30.2773C26.3252 30.3799 26.4643 30.4375 26.6094 30.4375C26.7544 30.4375 26.8935 30.3799 26.9961 30.2773C27.0986 30.1748 27.1562 30.0357 27.1562 29.8906V28.7969C27.1562 28.6518 27.0986 28.5127 26.9961 28.4102C26.8935 28.3076 26.7544 28.25 26.6094 28.25C26.4643 28.25 26.3252 28.3076 26.2227 28.4102ZM24.0352 28.4102C23.9326 28.5127 23.875 28.6518 23.875 28.7969V29.8906C23.875 30.0357 23.9326 30.1748 24.0352 30.2773C24.1377 30.3799 24.2768 30.4375 24.4219 30.4375C24.5669 30.4375 24.706 30.3799 24.8086 30.2773C24.9111 30.1748 24.9688 30.0357 24.9688 29.8906V28.7969C24.9688 28.6518 24.9111 28.5127 24.8086 28.4102C24.706 28.3076 24.5669 28.25 24.4219 28.25C24.2768 28.25 24.1377 28.3076 24.0352 28.4102ZM21.8477 28.4102C21.7451 28.5127 21.6875 28.6518 21.6875 28.7969V29.8906C21.6875 30.0357 21.7451 30.1748 21.8477 30.2773C21.9502 30.3799 22.0893 30.4375 22.2344 30.4375C22.3794 30.4375 22.5185 30.3799 22.6211 30.2773C22.7236 30.1748 22.7812 30.0357 22.7812 29.8906V28.7969C22.7812 28.6518 22.7236 28.5127 22.6211 28.4102C22.5185 28.3076 22.3794 28.25 22.2344 28.25C22.0893 28.25 21.9502 28.3076 21.8477 28.4102Z"
        fill="#2563EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8125 2.17519C16.6369 2.06171 16.431 2 16.2188 2H11.8438C11.5537 2 11.2755 2.11523 11.0704 2.32035C10.8652 2.52547 10.75 2.80367 10.75 3.09375V4.75844C10.4251 4.87378 10.1064 5.00593 9.79516 5.15437L8.61719 3.9775C8.41208 3.77245 8.13393 3.65726 7.84391 3.65726C7.55388 3.65726 7.27573 3.77245 7.07063 3.9775L3.9775 7.07063C3.77245 7.27573 3.65726 7.55388 3.65726 7.84391C3.65726 8.13393 3.77245 8.41208 3.9775 8.61719L5.15437 9.79516C5.00593 10.1064 4.87378 10.4251 4.75844 10.75H3.09375C2.80367 10.75 2.52547 10.8652 2.32035 11.0704C2.11523 11.2755 2 11.5537 2 11.8438V16.2188C2 16.5088 2.11523 16.787 2.32035 16.9921C2.52547 17.1973 2.80367 17.3125 3.09375 17.3125H4.75844C4.87437 17.6384 5.00672 17.9567 5.15437 18.2673L3.9775 19.4453C3.77245 19.6504 3.65726 19.9286 3.65726 20.2186C3.65726 20.5086 3.77245 20.7868 3.9775 20.9919L7.07063 24.085C7.27573 24.29 7.55388 24.4052 7.84391 24.4052C8.13393 24.4052 8.41208 24.29 8.61719 24.085L9.65625 23.047V17.283C8.97813 16.3719 8.5625 15.2541 8.5625 14.0312C8.5625 12.5808 9.13867 11.1898 10.1643 10.1643C11.1898 9.13867 12.5808 8.5625 14.0312 8.5625C15.051 8.5625 15.9978 8.85156 16.8125 9.34087V2.17519ZM16.8125 10.75H14.0312C12.2222 10.75 10.75 12.2222 10.75 14.0312V17.3125H16.8125V10.75ZM16.8125 18.4062H10.75V24.9688H16.8125V18.4062ZM16.8125 26.0625H10.75V29.3438C10.75 31.1528 12.2222 32.625 14.0312 32.625H16.8125V26.0625Z"
        fill="#60A5FA"
      />
    </svg>
  );
}
