import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { OkxSetupSeoSetting } from "../../components/seo-setting/seo-setting";
import { AccountName, AppRoute, AuthStatus } from "../../const";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setFirstScreenRendered } from "../../store/actions";
import { deleteSeoData } from "../../utils/general";

export default function OkxSetupScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { authStatus, isFirstScreenRendered } = useAppSelector(
    (state) => state
  );

  const autoHeadingRef = useRef<null | HTMLHeadingElement>(null);
  const appHeadingRef = useRef<null | HTMLHeadingElement>(null);

  const handleAutoBtnClick = () => {
    if (autoHeadingRef.current) {
      const target = autoHeadingRef.current.getBoundingClientRect().top - 5;

      window.scrollTo({
        top: target,
        behavior: "smooth",
      });
    }
  };

  const handleAppBtnClick = () => {
    if (appHeadingRef.current) {
      const target = appHeadingRef.current.getBoundingClientRect().top - 5;

      window.scrollTo({
        top: target,
        behavior: "smooth",
      });
    }
  };

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);

  return (
    <main className="main setup">
      <OkxSetupSeoSetting />
      <div className="setup__wrapper">
        <h1 className="setup__heading">OKX APIs Setup</h1>
        <h2 className="setup__heading-inner">Note</h2>
        <p className="setup__intro">
          If you do not already have an OKX account, you can create the one
          using{" "}
          <a
            className="link"
            href="https://www.okx.com/join/72377893"
            target="_blank"
            rel="noopener noreferrer"
          >
            this link
          </a>
          .
        </p>
        <ul className="setup__intro-list">
          <li className="setup__intro-item">
            <button
              className="setup__intro-scroll"
              onClick={handleAutoBtnClick}
            >
              Fast API
            </button>{" "}
            <span className="setup__intro-text">(Less than 1 minute)</span>
          </li>
          <li className="setup__intro-item">
            <button className="setup__intro-scroll" onClick={handleAppBtnClick}>
              Manual connection
            </button>{" "}
            <span className="setup__intro-text">(3-5 minutes)</span>
          </li>
        </ul>
        <h2 className="setup__heading-inner" ref={autoHeadingRef}>
          Fast API
        </h2>
        <p className="setup__intro">
          Connect your OKX account to SMARD in less than 1 minute using OAuth.
        </p>
        <ul className="setup__list">
          <li className="setup__item">
            <p className="setup__paragraph">
              1. Go to the{" "}
              <Link
                className="link"
                to={`${AppRoute.Accounts}/${AccountName.Okx}/`}
              >
                OKX details page
              </Link>{" "}
              and click on the <b>"Connect my account"</b> button.
            </p>

            <img
              className="setup__img"
              src={`${process.env.PUBLIC_URL}/img/screens/okx-setup/okx-setup-auto-1.jpg`}
              alt="Okx setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              2. This will redirect you to the OKX log in page. Here you can log
              in or create a new OKX account.
            </p>
            <img
              className="setup__img"
              src={`${process.env.PUBLIC_URL}/img/screens/okx-setup/okx-setup-auto-2.jpg`}
              alt="Okx setup"
              width="100%"
              height="auto"
            />
          </li>
          <li className="setup__item">
            <p className="setup__paragraph">
              3. Complete the login or sign up process and <b>Approve</b> the
              connection.
            </p>
            <img
              className="setup__img setup__img--medium"
              src={`${process.env.PUBLIC_URL}/img/screens/okx-setup/okx-setup-auto-3.jpg`}
              alt="Okx setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              4. Return to the{" "}
              <Link
                className="link"
                to={`${AppRoute.Accounts}/${AccountName.Okx}/`}
              >
                OKX details page
              </Link>{" "}
              and specify the amount to be traded by SMARD and then press the{" "}
              <b>"Start"</b> button
            </p>
            <img
              className="setup__img"
              src={`${process.env.PUBLIC_URL}/img/screens/okx-setup/okx-setup-auto-4.jpg`}
              alt="Okx setup"
              width="100%"
              height="auto"
            />
          </li>
        </ul>
        <p className="setup__post">
          You are all set for automated trading with SMARD!
        </p>
        <h2 className="setup__heading-inner" ref={appHeadingRef}>
          Manual connection
        </h2>
        <ul className="setup__list">
          <li className="setup__item">
            <p className="setup__paragraph">
              1. Go to the{" "}
              <a
                className="link"
                href="https://www.okx.com/account/my-api"
                target="_blank"
                rel="noopener noreferrer"
              >
                API keys section
              </a>{" "}
              on OKX.
            </p>
            <p className="setup__paragraph">
              You must enable 2FA to add API keys. If you see this message,
              please follow OKX's instructions:
            </p>
            <img
              className="setup__img setup__img--medium"
              src={`${process.env.PUBLIC_URL}/img/screens/okx-setup/okx-setup-app-1.jpg`}
              alt="Okx setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">2. Click "Create v5 API".</p>
            <img
              className="setup__img"
              src={`${process.env.PUBLIC_URL}/img/screens/okx-setup/okx-setup-app-2.jpg`}
              alt="Okx setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              3. Choose "SMARD" from the App name list.
            </p>
            <img
              className="setup__img setup__img--app3"
              src={`${process.env.PUBLIC_URL}/img/screens/okx-setup/okx-setup-app-3.jpg`}
              alt="Okx setup"
              width="100%"
              height="auto"
            />
          </li>
          <li className="setup__item">
            <p className="setup__paragraph">
              4. Name your key and enter the passphrase. It's a password for the
              API key. Make a note of it as you can't add an API to SMARD
              without it.
            </p>
            <img
              className="setup__img setup__img--medium"
              src={`${process.env.PUBLIC_URL}/img/screens/okx-setup/okx-setup-app-4.jpg`}
              alt="Okx setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              5. Check the <b>"Trade"</b> permission.
            </p>
            <img
              className="setup__img setup__img--medium"
              src={`${process.env.PUBLIC_URL}/img/screens/okx-setup/okx-setup-app-5.jpg`}
              alt="Okx setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              6. Enter 2FA code/-s then click{" "}<b>"Confirm"</b>.
            </p>
            <img
              className="setup__img setup__img--app6"
              src={`${process.env.PUBLIC_URL}/img/screens/okx-setup/okx-setup-app-6.jpg`}
              alt="Okx setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              7. Confirm your created API keys.
            </p>
            <img
              className="setup__img setup__img--app7"
              src={`${process.env.PUBLIC_URL}/img/screens/okx-setup/okx-setup-app-7.jpg`}
              alt="Okx setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              8. Finally, you have to click on the{" "}
              <b>"Enter API keys manually"</b> button on the{" "}
              <Link
                className="link"
                to={`${AppRoute.Accounts}/${AccountName.Okx}/`}
              >
                OKX details page
              </Link>{" "}.
            </p>
            <img
              className="setup__img"
              src={`${process.env.PUBLIC_URL}/img/screens/okx-setup/okx-setup-app-8.jpg`}
              alt="Okx setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              9. Copy/paste the <b>API Key</b>, <b>Secret Key</b>,{" "}
              <b>Passphrase</b> and <b>specify the amount</b> to trade.
            </p>
            <img
              className="setup__img"
              src={`${process.env.PUBLIC_URL}/img/screens/okx-setup/okx-setup-app-9.jpg`}
              alt="Okx setup"
              width="100%"
              height="auto"
            />
          </li>
        </ul>
        <p className="setup__post">
          Congratulations! Now you can experience the benefits of fully
          automated trading.
        </p>
      </div>
    </main>
  );
}
