import { useState } from "react";

import FooterList1 from "./footer-list1";
import FooterList2 from "./footer-list2";
import FooterList3 from "./footer-list3";
import Contacts from "../contacts/contacts";
import { showFullCurrentYear } from "../../utils/datetime";
import { checkWidthIfForFooterStyle } from "../../utils/breakpoint";
import { AuthStatus, RERENDER_DELAY } from "../../const";
import { debounce } from "../../utils/chart/chart";
import { useAppSelector } from "../../hooks";

export default function Footer(): JSX.Element {
  const { authStatus } = useAppSelector((state) => state);

  const isNoAuth = authStatus === AuthStatus.NoAuth;

  const [isOneRowInMobile, setOneRowInMobile] = useState<boolean>(
    checkWidthIfForFooterStyle()
  );

  window.addEventListener(
    "resize",
    debounce(() => {
      setOneRowInMobile(checkWidthIfForFooterStyle());
    }, RERENDER_DELAY)
  );

  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div
          className={`footer__nav-contacts ${
            isOneRowInMobile ? "footer__nav-contacts--one-row" : ""
          }`}
        >
          <div className="footer__list footer__list--1">
            <FooterList1 isNoAuth={isNoAuth} />
          </div>

          <div className="footer__list footer__list--2">
            <FooterList2 />
          </div>

          <div className="footer__contacts footer__contacts--inner">
            <Contacts />
          </div>

          <div className="footer__list footer__list--3">
            <FooterList3 />
          </div>
        </div>

        <div className="footer__contacts footer__contacts--outer">
          <Contacts />
        </div>

        <div className="footer__info">
          <span className="footer__copyright">{`© 2021-${showFullCurrentYear()} SMARD LLC.`}</span>
          <p className="footer__note">
            The information and features of the Website are provided only for
            information purposes and in no way constitute any form of any
            solicitation to offer, buy, sell, recommend, advice to buy and/or
            sell or otherwise acquire or dispose of, any interest or any share
            in any financial instrument and/or digital assets, or any offer,
            advice or recommendation to purchase or follow any investment
            strategy.
          </p>
          <p className="footer__note">
            You are solely responsible for any actions done with the Website and
            agree and acknowledge that all investments and trading in any
            digital assets and/or any financial instruments will be done at your
            own risk and expertise.
          </p>
        </div>
      </div>
    </footer>
  );
}
