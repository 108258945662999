import { createAction } from "@reduxjs/toolkit";

import { StateAction } from "./action-types";
import { ReferralsType } from "../types/referrals";
import { CalcChartsType } from "../types/chart";
import { CoinType, TradableCoinType } from "../types/coins";
import {
  QuotesItemsType,
  ResponseStatusType,
  StrategyDetailsType,
  UserAmountChangesType,
} from "../types/details";
import {
  AddedCardStatusType,
  CreditCardType,
  CryptoWalletType,
  FailedTransactionsType,
  LastPaymentType,
  PaymentMethodsType,
} from "../types/payment";
import {
  NavAccountType,
  MessagesType,
  NotificationType,
} from "../types/general";

// Message
export const requireMessages = createAction<MessagesType>(
  StateAction.Message.RequireMessages
);
export const loadNotifications = createAction<null | NotificationType[]>(
  StateAction.Message.LoadNotifications
);
export const setNotificationsLoading = createAction<boolean>(
  StateAction.Message.SetNotificationsLoading
);
export const resetNotificationsInMessages = createAction<null>(
  StateAction.Message.ResetNotificationsInMessages
);

// Nav
export const loadNavAccounts = createAction<NavAccountType[]>(
  StateAction.Nav.LoadNavAccounts
);
export const setAccountsLoading = createAction<boolean>(
  StateAction.Nav.SetAccountsLoading
);

// Strategy
export const loadStrategyDetails = createAction<null | StrategyDetailsType>(
  StateAction.Strategy.LoadStrategyDetails
);
export const setStrategyDetailsLoading = createAction<boolean>(
  StateAction.Strategy.SetStrategyDetailsLoading
);

export const setUseAllAmount = createAction<boolean>(
  StateAction.Strategy.SetUseAllAmount
);
export const setChangeAmount = createAction<boolean>(
  StateAction.Strategy.SetChangeAmount
);
export const loadUseAllAmount = createAction<boolean | null>(
  StateAction.Strategy.LoadUseAllAmount
);
export const setLoadUseAllAmountIsLoading = createAction<boolean>(
  StateAction.Strategy.SetLoadUseAllAmountIsLoading
);
export const setUseAllAmountIsLoading = createAction<boolean>(
  StateAction.Strategy.SetUseAllAmountIsLoading
);
export const setChangeAmountIsLoading = createAction<boolean>(
  StateAction.Strategy.SetChangeAmountIsLoading
);
export const setChangeAmountStatus = createAction<ResponseStatusType | null>(
  StateAction.Strategy.SetChangeAmountStatus
);
export const setUseAllAmountStatus = createAction<ResponseStatusType | null>(
  StateAction.Strategy.SetUseAllAmountStatus
);
export const loadUserAmountChanges = createAction<UserAmountChangesType | null>(
  StateAction.Strategy.LoadUserAmountChanges
);
export const setIsLoadUserAmountChangesLoading = createAction<boolean>(
  StateAction.Strategy.IsLoadUserAmountChangesLoading
);

// Integration
export const setIntegrationOpened = createAction<boolean>(
  StateAction.Strategy.SetIntegrationOpened
);
export const setFixDivergenceLoading = createAction<boolean>(
  StateAction.Strategy.SetFixDivergenceLoading
);

// Renew keys
export const setRenewKeysOpened = createAction<boolean>(
  StateAction.Strategy.SetRenewKeysOpened
);

// Referrals
export const loadReferrals = createAction<ReferralsType>(
  StateAction.Referrals.LoadReferrals
);
export const setReferralsLoading = createAction<boolean>(
  StateAction.Referrals.SetReferralsLoading
);

// Chart
export const loadCalcChart = createAction<null | CalcChartsType>(
  StateAction.Chart.LoadCalcChart
);
export const setCalcChartLoading = createAction<boolean>(
  StateAction.Chart.SetCalcChartLoading
);

// Payment
export const loadClientSecret = createAction<string>(
  StateAction.Payment.LoadClientSecret
);
export const loadPaymentMethods = createAction<PaymentMethodsType>(
  StateAction.Payment.LoadPaymentMethods
);
export const setPaymentMethodsLoading = createAction<boolean>(
  StateAction.Payment.SetPaymentMethodsLoading
);
export const setSendMethodsOrderStatus = createAction<null | boolean>(
  StateAction.Payment.SetSendMethodsOrderStatus
);
export const setChargeMe = createAction<boolean>(
  StateAction.Payment.SetChargeMe
);
export const addCard = createAction<CreditCardType>(
  StateAction.Payment.AddCard
);
export const setAddCardLoading = createAction<boolean>(
  StateAction.Payment.SetAddCardLoading
);
export const setSendAddedCardStatus = createAction<null | AddedCardStatusType>(
  StateAction.Payment.SetSendAddedCardStatus
);
export const deleteCard = createAction<string>(StateAction.Payment.DeleteCard);
export const loadLastPayments = createAction<LastPaymentType[]>(
  StateAction.Payment.LoadLastPayments
);
export const setLastPaymentsLoading = createAction<boolean>(
  StateAction.Payment.SetLastPaymentsLoading
);
export const loadCryptoWallet = createAction<null | CryptoWalletType>(
  StateAction.Payment.LoadCryptoWallet
);
export const addDepositInLastPayments = createAction<LastPaymentType>(
  StateAction.Payment.AddDepositInLastPayments
);
export const setSendDepositInLastPaymentsStatus = createAction<null | boolean>(
  StateAction.Payment.SetSendDepositInLastPaymentsStatus
);
export const loadFailedTransactions = createAction<FailedTransactionsType[]>(
  StateAction.Payment.LoadFailedTransactions
);
export const setFailedTransactionsLoading = createAction<boolean>(
  StateAction.Payment.SetFailedTransactionsLoading
);
export const setFailedTransactionsAgainLoading = createAction<boolean>(
  StateAction.Payment.SetFailedTransactionsAgainLoading
);
export const setCryptoFormChosen = createAction<boolean>(
  StateAction.Payment.SetCryptoFormChosen
);
export const setCardFormChosen = createAction<boolean>(
  StateAction.Payment.SetCardFormChosen
);

// Interface
export const setFirstScreenRendered = createAction<boolean>(
  StateAction.Interface.SetFirstScreenRendered
);
export const setPartnershipFormFocused = createAction<boolean>(
  StateAction.Interface.SetPartnershipFormFocused
);
export const SetChangeBalanceFormOpened = createAction<boolean>(
  StateAction.Interface.SetChangeBalanceFormOpened
);

export const setOpenedHint = createAction<null | string>(
  StateAction.Interface.SetOpenedHint
);
export const addReadHintId = createAction<string>(
  StateAction.Interface.AddReadHintId
);
export const setNavAccountsOpened = createAction<boolean>(
  StateAction.Interface.SetNavAccountsOpened
);
export const setNotificationsOpened = createAction<boolean>(
  StateAction.Interface.SetNotificationsOpened
);
export const setPersonalOpened = createAction<boolean>(
  StateAction.Interface.SetPersonalOpened
);

// Modal
export const setAuthModalOpened = createAction<boolean>(
  StateAction.Modal.SetAuthModalOpened
);
export const setAuthModalOpenedFromExchanges = createAction<boolean>(
  StateAction.Modal.SetAuthModalOpenedFromExchanges
);
export const setErrorModalOpened = createAction<boolean>(
  StateAction.Modal.SetErrorModalOpened
);
export const resetErrorInMessages = createAction<null>(
  StateAction.Modal.ResetErrorInMessages
);
export const setStopModalOpened = createAction<boolean>(
  StateAction.Modal.SetStopModalOpened
);
export const setStopModalId = createAction<null | string>(
  StateAction.Modal.SetStopModalId
);
export const setDeleteCardModalOpened = createAction<boolean>(
  StateAction.Modal.SetDeleteCardModalOpened
);
export const setDeleteCardModalId = createAction<null | string>(
  StateAction.Modal.SetDeleteCardModalId
);
export const setOkxModalOpened = createAction<boolean>(
  StateAction.Modal.SetOkxModalOpened
);
export const setOkxModalRendered = createAction<boolean>(
  StateAction.Modal.SetOkxModalRendered
);
export const setBitgetModalOpened = createAction<boolean>(
  StateAction.Modal.SetBitgetModalOpened
);
export const setBitgetModalRendered = createAction<boolean>(
  StateAction.Modal.SetBitgetModalRendered
);
export const setBybiytModalOpened = createAction<boolean>(
  StateAction.Modal.SetBybitModalOpened
);
export const setBybitModalRendered = createAction<boolean>(
  StateAction.Modal.SetBybitModalRendered
);

// Calendar
export const setIntegrationCalendarOpened = createAction<boolean>(
  StateAction.Calendar.SetIntegrationCalendarOpened
);

// Coins
export const loadTradableCoins = createAction<TradableCoinType[]>(
  StateAction.Coins.LoadTradableCoins
);
export const setTradableCoinsLoading = createAction<boolean>(
  StateAction.Coins.SetTradableCoinsLoading
);
export const loadCoin = createAction<null | CoinType>(
  StateAction.Coins.LoadCoin
);
export const setCoinLoading = createAction<boolean>(
  StateAction.Coins.SetCoinLoading
);
