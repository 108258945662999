import { forwardRef, useState } from "react";

import OverviewCurrent from "../elements/overview-current";
import OverviewFromStart from "../elements/overview-from-start";
import OverviewInitial from "../elements/overview-initial";
import OverviewSigns from "../elements/overview-signs";
import OverviewLastMonth from "../elements/overview-last-month";
import OverviewBtn from "../elements/overview-btn";
import OverviewDynamics from "../elements/overview-dynamics";
import { RERENDER_DELAY, StrategyStatus } from "../../../const";
import { StrategyType } from "../../../types/strategy";
import OverviewYourChanges from "../elements/overview-your-changes";
import { UserAmountChangesType } from "../../../types/details";
import { checkIfDesktop } from "../../../utils/breakpoint";
import { debounce } from "../../../utils/chart/chart";

type OverviewStartedProps = {
  overview: StrategyType;
  isTabletAndMore: boolean;
  index: number;
  isNavigateAble: boolean;
  userAmountChanges: UserAmountChangesType | null;
};

function OverviewStarted(
  {
    overview,
    isTabletAndMore,
    index,
    isNavigateAble,
    userAmountChanges,
  }: OverviewStartedProps,
  ref: any
): JSX.Element {
  const [isDesktop, setIsDesktop] = useState<boolean>(checkIfDesktop());

  const currentUserAmountChange =
    userAmountChanges?.find((el) => el.code === overview.code)
      ?.userAmountChanges ?? 0;

  window.addEventListener(
    "resize",
    debounce(() => {
      setIsDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );

  return (
    <div className="overview-started">
      <div className="overview-board__total-wrapper overview-started__total-wrapper">
        <OverviewInitial
          overview={overview}
          strategyStatus={StrategyStatus.Started}
          isTabletAndMore={isTabletAndMore}
        />
        <OverviewCurrent
          overview={overview}
          strategyStatus={StrategyStatus.Started}
          isTabletAndMore={isTabletAndMore}
        />
      </div>
      <div className="overview-board__total-wrapper overview-started__total-wrapper">
        <OverviewFromStart
          overview={overview}
          strategyStatus={overview.status}
          isTabletAndMore={isTabletAndMore}
        />
        {isDesktop ? (
          <OverviewLastMonth
            overview={overview}
            strategyStatus={overview.status}
            isTabletAndMore={isTabletAndMore}
          />
        ) : null}
        <OverviewYourChanges
          isTabletAndMore={isTabletAndMore}
          overview={overview}
          strategyStatus={overview.status}
          currentUserAmountChange={currentUserAmountChange}
        />
      </div>
      <OverviewSigns
        overview={overview}
        currentUserAmountChange={currentUserAmountChange}
      />
      <OverviewDynamics overview={overview} />
      <OverviewBtn
        overview={overview}
        index={index}
        ref={ref}
        isNavigateAble={isNavigateAble}
      />
    </div>
  );
}

export default forwardRef<
  {
    buttonRef: React.RefObject<HTMLButtonElement> | null;
    hintButtonRef: React.RefObject<HTMLDivElement> | null;
  },
  OverviewStartedProps
>(OverviewStarted);
