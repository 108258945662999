import { pricingFeesData } from "./pricing-fees-data";
import PricingFeesItem from "./pricing-fees-item";

function PricingFeesSection() {
  return (
    <section className="landing__item--pricing-fees">
      <h1 className="visually-hidden">Pricing</h1>
      <h2 className="landing__title">Pricing Details</h2>
      <div className="pricing__fees-container">
        {pricingFeesData.map((data) => {
          return (
            <PricingFeesItem
              label={data.label}
              number={data.number}
              numberNote={data.numberNote}
              note={data.note}
            />
          );
        })}
      </div>
    </section>
  );
}

export default PricingFeesSection;
