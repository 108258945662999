import BoardSign from "../../board-sign/board-sign";
import { formatStrategy } from "../../../utils/amount";
import { StrategyType } from "../../../types/strategy";

type OverviewSignsProps = {
  overview: StrategyType;
  currentUserAmountChange: number;
};

export default function OverviewSigns({
  overview,
  currentUserAmountChange,
}: OverviewSignsProps): JSX.Element {
  const amount = overview.profit_from_start;

  return amount !== null ? (
    <>
      <div className="overview-board__sign overview-board__sign--calc">
        {formatStrategy(amount).amount >= 0 ? (
          <BoardSign sign="plus" isColored />
        ) : (
          <BoardSign sign="minus" isColored />
        )}
      </div>
      <div className="overview-board__sign overview-board__sign--your-changes">
        {formatStrategy(currentUserAmountChange).amount >= 0 ? (
          <BoardSign sign="plus" isYourChanges />
        ) : (
          <BoardSign sign="minus" isYourChanges />
        )}
      </div>

      <div className="overview-board__sign overview-board__sign--equal">
        <BoardSign sign="equal" />
      </div>
    </>
  ) : (
    <></>
  );
}
