import { PricingFeesDataType } from "./pricing-fees-data";

function PricingFeesItem({
  label,
  note,
  number,
  numberNote,
}: PricingFeesDataType) {
  return (
    <div className="pricing__fees-item-container">
      <p className="pricing__fees-item-header">{label}</p>
      <div className="pricing__fees-item-number-container">
        <span className="pricing__fees-item-number">{number}</span>
        <span className="pricing__fees-item-number-note">{numberNote}</span>
      </div>
      <p className="pricing__fees-item-note">{note}</p>
    </div>
  );
}

export default PricingFeesItem;
