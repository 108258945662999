import { useEffect, useState } from "react";

import Bots from "../../components/bots/bots";
import BybitFaq from "../../components/faq/bybit-faq";
import BybitSteps from "../../components/steps/bybit-steps";
import BybitPromo from "../../components/promos/bybit-promo";
import BybitBriefly from "../../components/brieflies/bybit-briefly";
import { deleteSeoData } from "../../utils/general";
import { BybitLandingSeoSetting } from "../../components/seo-setting/seo-setting";
import { trackEvent } from "../../utils/tracking";
import { checkIfMobile } from "../../utils/breakpoint";
import { debounce } from "../../utils/chart/chart";
import { RERENDER_DELAY } from "../../const";
import GeneralExchangePluses from "../../components/pluses/general-exchange-pluses";

export default function BybitScreen(): JSX.Element {
  const [isMobile, setIsMobile] = useState<boolean>(checkIfMobile());

  // any page
  useEffect(() => {
    trackEvent("BybitLanding", "PageView");
    deleteSeoData();
  }, []);
  //

  window.addEventListener(
    "resize",
    debounce(() => {
      setIsMobile(checkIfMobile());
    }, RERENDER_DELAY)
  );

  return (
    <main className="main main--landing landing">
      <BybitLandingSeoSetting />
      <div className="landing__wrapper">
        <BybitPromo isMobile={isMobile} />
        <GeneralExchangePluses />
        <BybitSteps />
        <BybitBriefly />
        <Bots />
        <BybitFaq />
      </div>
    </main>
  );
}
