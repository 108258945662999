import { SyntheticEvent } from "react";

import Button from "../../button/button";
import Hint from "../../hint/hint";
import DetailsLink from "../elements/details-link";
import DetailsInitial from "../elements/details-initial";
import DetailsCurrent from "../elements/details-current";
import DetailsFromStart from "../elements/details-from-start";
import DetailsSigns from "../elements/details-signs";
import { StrategyOverviewType } from "../../../types/details";
import { setRenewKeysOpened } from "../../../store/actions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { HINTS_TEXT, StrategyStatus } from "../../../const";
import DetailsYourChanges from "../elements/details-your-changes";

type BlockDemoProps = {
  overview: StrategyOverviewType;
  isTabletAndMore: boolean;
  isRenewKeysFormOpened: boolean;
};

export default function BlockDemo({
  overview,
  isTabletAndMore,
  isRenewKeysFormOpened,
}: BlockDemoProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { userAmountChanges } = useAppSelector((state) => state);

  const handleRenewKeysClick = (evt: SyntheticEvent) => {
    const element = evt.currentTarget as HTMLButtonElement;
    dispatch(setRenewKeysOpened(true));
    element.blur();
  };

  const currentUserAmountChange =
    userAmountChanges?.find((el) => el.code === overview.code)
      ?.userAmountChanges ?? 0;

  return (
    <div className="block-demo">
      <div className="block-demo__total-wrapper">
        <DetailsInitial
          overview={overview}
          strategyStatus={StrategyStatus.Demo}
          isTabletAndMore={isTabletAndMore}
        />
        <DetailsCurrent
          overview={overview}
          strategyStatus={StrategyStatus.Demo}
          isTabletAndMore={isTabletAndMore}
        />
      </div>
      <div className="block-demo__total-wrapper">
        <DetailsFromStart
          overview={overview}
          strategyStatus={StrategyStatus.Demo}
          isTabletAndMore={isTabletAndMore}
        />
        <DetailsYourChanges
          overview={overview}
          strategyStatus={StrategyStatus.Demo}
          isTabletAndMore={isTabletAndMore}
          currentUserAmountChange={currentUserAmountChange}
        />
      </div>

      <DetailsSigns
        overview={overview}
        strategyStatus={StrategyStatus.Demo}
        currentUserAmountChange={currentUserAmountChange}
        isYourChanges
      />

      <div className="block-demo__btns-wrapper">
        <div className="block-demo__renew-btn-wrapper">
          <Button
            classElement={`block-demo__renew-btn ${
              isRenewKeysFormOpened ? "btn--disabled" : ""
            }`}
            pattern="white-blue"
            text="Renew keys"
            icon="key-in-circle"
            type="button"
            handleClick={handleRenewKeysClick}
          />
          <div className="block-demo__link">
            <DetailsLink accountName={overview.code} />
          </div>

          <Hint
            type="block-demo__renew-hint"
            id="details-strategy/renew-keys"
            hintText={HINTS_TEXT["details/strategy/renew-keys"]}
          />
        </div>

        <div className="block-demo__stop-btn-wrapper">
          <Button
            classElement="block-demo__stop-btn btn--blocked"
            pattern="blue-transparent"
            text="Stop"
            icon="error"
            type="button"
            size="small"
          />

          <Hint
            type="block-demo__stop-hint"
            id="details-strategy/stop"
            hintText={HINTS_TEXT["details/strategy/stop"]}
          />
        </div>
      </div>
    </div>
  );
}
