import { StrategyOverviewType } from "../../../types/details";
import { formatStrategy } from "../../../utils/amount";
import {
  addYourChangesSign,
  displayStrategyAmount,
} from "../../../utils/component/overview";

type DetailsYourChangesProps = {
  strategyStatus: string;
  isTabletAndMore: boolean;
  overview: StrategyOverviewType;
  currentUserAmountChange?: number;
};

function DetailsYourChanges({
  strategyStatus,
  isTabletAndMore,
  overview,
  currentUserAmountChange = 0,
}: DetailsYourChangesProps) {

  return (
    <div className="details-board__total details-board__total--your-changes">
      <p className="details-board__amount">
        {addYourChangesSign(
          isTabletAndMore,
          strategyStatus,
          formatStrategy(currentUserAmountChange).amount,
          formatStrategy(currentUserAmountChange).amount
        )}
        <span className="details-board__number">
          {displayStrategyAmount(
            "initial",
            strategyStatus,
            isTabletAndMore,
            currentUserAmountChange,
            overview.currency,
            currentUserAmountChange
          )}
        </span>
      </p>
      <span className="details-board__text">Your changes</span>
    </div>
  );
}

export default DetailsYourChanges;
