import { FaqList } from "../../../types/general";
import FaqAccountManagmentIcon from "../../icons/faq/faq-account-managment-icon";
import FaqConnectionIcon from "../../icons/faq/faq-connection-icon";
import FaqMoneyIcon from "../../icons/faq/faq-money-icon";
import FaqPaymentsIcon from "../../icons/faq/faq-payments-icon";
import FaqResultsIcon from "../../icons/faq/faq-results-icon";
import FaqSecurityIcon from "../../icons/faq/faq-security-icon";
import { FaqScreenAccountManagmentData } from "./faq-screen-account-managment-data";
import { FaqScreenConnectionData } from "./faq-screen-connection-data";
import { FaqScreenMyMoneyData } from "./faq-screen-my-money-data";
import { FaqScreenPaymentsData } from "./faq-screen-payments-data";
import { FaqScreenSecurityData } from "./faq-screen-security-data";
import { FaqScreenTradingResults } from "./faq-screen-trading-results-data";

export const faqScreenListData: FaqList[] = [
  {
    label: "My money",
    faqData: FaqScreenMyMoneyData,
    icon: FaqMoneyIcon(),
  },
  {
    label: "Connection",
    faqData: FaqScreenConnectionData,
    icon: FaqConnectionIcon(),
  },
  {
    label: "Account management",
    faqData: FaqScreenAccountManagmentData,
    icon: FaqAccountManagmentIcon(),
  },
  {
    label: "Payments",
    faqData: FaqScreenPaymentsData,
    icon: FaqPaymentsIcon(),
  },
  {
    label: "Security",
    faqData: FaqScreenSecurityData,
    icon: FaqSecurityIcon(),
  },
  {
    label: "Trading results explained",
    faqData: FaqScreenTradingResults,
    icon: FaqResultsIcon(),
  },
];
