import { useEffect } from "react";
import { Link } from "react-router-dom";

import { TermsSeoSetting } from "../../components/seo-setting/seo-setting";
import { AppRoute, AuthStatus } from "../../const";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setFirstScreenRendered } from "../../store/actions";
import { deleteSeoData } from "../../utils/general";

export default function TermsScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { authStatus, isFirstScreenRendered } = useAppSelector(
    (state) => state
  );

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);
  //

  return (
    <main className="main terms">
      <TermsSeoSetting />
      <h1 className="terms__heading">Terms of Service</h1>

      <ul className="terms__list">
        {/* 1.	General */}
        <li className="terms__item">
          <h2 className="terms__question">1. General</h2>
          <p className="terms__paragraph">
            1.1. In these Terms of Service "we", "us" or "our" refer to the
            website{" "}
            <a
              className="link"
              href="https://smard.club"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://smard.club
            </a>{" "}
            and any webpages thereof (collectively the "Website"), operated by
            Smard LLC, registered under the laws of the United States of
            America, the state of Wyoming, and its authorised representatives
            while "You" or "Your" refer to You personally and any other person
            who accesses on Your behalf, and/or on whose behalf You are
            accessing the Website.
          </p>

          <p className="terms__paragraph">
            1.2. By accessing this Website and using the services available
            therein You confirm and warrant that You have read, understood, and
            agreed to comply with and follow these Terms of Service as well as{" "}
            <Link className="link" to={AppRoute.Disclaimers}>
              Disclaimers
            </Link>{" "}
            ,{" "}
            <Link className="link" to={AppRoute.Policy}>
              Privacy Policy
            </Link>{" "}
            and any other supplementary documents available on the Website.
          </p>

          <p className="terms__paragraph">
            1.3. You agree and acknowledge that We can unilaterally amend these
            Terms of Service from time to time without any specific notice
            served to You to that effect in advance. You are solely responsible
            for regular assessment of the Website on any updated Terms of
            Service. Your usage of the Website and its services after
            publication of any updated Terms of Service on the Website
            constitutes Your acceptance of these updated terms. If You do not
            agree to or cannot follow Your obligations under the updated Terms
            of Service, You undertake to immediately terminate Your usage of the
            Website and services available therein.
          </p>
          <p className="terms__paragraph">
            1.4. Our Website provides You with software You can use to automate
            Your trading. These Terms of Service govern Your usage of the
            Website. Any trading done through software and tools available on
            Our Website is done at Your own risk and Your own decision to trade
            and/or invest in any form of digital and/or financial asset. All
            Your investments can be lost with no return. You hereby confirm and
            warrant that You have read and agreed with the information on{" "}
            <Link className="link" to={AppRoute.Disclaimers}>
              usage of Our Website and risk disclaimers
            </Link>
            .
          </p>
          <p className="terms__paragraph">
            1.5. You acknowledge and agree that You can access this Website and
            use its services only if You are not residing in, and/or not under
            control of Afghanistan, Iran, Cuba, Syria, North Korea and Canada,
            and if You personally are not subject to any economic sanctions
            issued by any competent authorities from time to time. You also must
            be at least 18 years old to use the Website and its services defined
            under clause 3 below. We can suspend Your usage of the Website and
            its services any time with or without a notice if in Our sole
            discretion We determine that You do not meet any criteria for usage
            of the Website.
          </p>
        </li>

        {/* 2.	Registration and account protection */}
        <li className="terms__item">
          <h2 className="terms__question">
            2. Registration and account protection
          </h2>
          <p className="terms__paragraph">
            2.1. To access the Website and use its software You need to complete
            the registration process. You will register on the Website and will
            receive an access to Your personal account by login through and
            connecting either of the following services:
          </p>

          <ul className="terms__sked">
            <li className="terms__sked-item">Your Google account;</li>
            <li className="terms__sked-item">Your Apple account;</li>
            <li className="terms__sked-item">Your Telegram account.</li>
          </ul>
          <p className="terms__paragraph">
            2.2. You are solely responsible for the safety of Your account
            details and accesses to log in method You have used on registration
            stage. We are not liable for any direct and/or indirect loss or
            damage arising out or in connection with any unauthorised access to
            Your account on the Website.
          </p>
        </li>

        {/* 3.	Services */}
        <li className="terms__item">
          <h2 className="terms__question">3. Services</h2>
          <p className="terms__paragraph">
            3.1. The Website provides Trading enhancement software, and You may,
            at Your sole initiative and discretion, connect such software (the{" "}
            <b>"Trading enhancement software"</b>) to Your external
            cryptocurrency exchange and/or brokerage accounts.
          </p>
          <p className="terms__paragraph">
            3.2. The connection is done through API integration in Your personal
            account on the Website where You provide a specific API key and
            secret key (if any) from Your external cryptocurrency exchange
            and/or brokerage account to enable the Website’s Trading enhancement
            software to connect to Your external cryptocurrency exchange and/or
            brokerage accounts. Once You are connected through API You will have
            an option to enter the amount of digital and/or financial assets You
            want to use with the Website, so that Our Trading enhancement
            software can automate Your trading experience.
          </p>
          <p className="terms__paragraph">
            3.3. You can terminate the use and access of such Trading
            enhancement software by submitting an instruction to that effect at
            any time in Your personal account. The Trading enhancement software
            will then cease to have any access to Your external cryptocurrency
            exchange and/or brokerage accounts within a reasonable period of
            time required to disconnect it.
          </p>
          <p className="terms__paragraph">
            3.4. The Website and its Trading enhancement software do not provide
            any tax, legal or investment advice to follow any investment
            strategy. All decisions to trade and/or invest in any digital and/or
            financial assets through the Website and its Trading enhancement
            software are done at Your own risk and intention.
          </p>
          <p className="terms__paragraph">
            3.5. For the avoidance of doubt, We and the Website will never hold
            or control any of Your digital or other financial assets. At all
            times, any and all of Your assets are held with the cryptocurrency
            exchange and/or broker of Your own choice who remains responsible
            for their safekeeping and protection in accordance with their own
            published and provided terms, guidelines, rules and regulations.
          </p>
        </li>

        {/* 4.	Fees and taxes */}
        <li className="terms__item">
          <h2 className="terms__question">4. Fees and taxes</h2>
          <p className="terms__paragraph">
            4.1. The services described under clause 3 above are charged on
            monthly basis (once in 30 calendar days) as follows:
          </p>
          <p className="terms__paragraph">
            4.1.1. Access fee is fixed at USD 1 (one US Dollar) or its
            equivalent in other currency subject to official exchange rate per
            month;
          </p>
          <p className="terms__paragraph">
            4.1.2. Service fee is fixed at 10% (ten per cent) of Your earnings
            received through the Website per month. We follow win-win approach
            and will not charge You with service fee if there was no financial
            success.
          </p>
          <p className="terms__paragraph">
            4.2. The fees are charged starting from the day You started to use
            the Website’s Trading enhancement software after successful
            integration as referred to under clause 3.2 above and, in cases
            where You connect multiple external cryptocurrency exchange and/or
            brokerage accounts, each of such connected accounts will have its
            own date for payment shown in "Payments" page in Your Personal
            account on the Website. If any amount for any connected accounts is
            unpaid, We are free to suspend Your usage of the Website’s Trading
            enhancement software until You will settle all balance due.
          </p>
          <p className="terms__paragraph">
            4.3. Any fees due will be shown on "Payments" page in Your personal
            account on the Website. You can settle the fees by entering the
            details of Your debit card for direct charge.
          </p>
          <p className="terms__paragraph">
            4.4. You are solely responsible for any taxes which can arise from
            any profit You can receive through the Website’s Trading enhancement
            software. Nor we, nor the Website can determine to which taxes You
            are due. It is Your sole responsibility to receive a professional
            tax consultation from an independent tax advisor if You need it. We
            will never pay any taxes on Your behalf since We will never have any
            access to any of Your assets as laid down in clause 3.5 above.
          </p>
        </li>

        {/* 5. License to use the Trading enhancement software and intellectual
            property rights */}
        <li className="terms__item">
          <h2 className="terms__question">
            5. License to use the Trading enhancement software and intellectual
            property rights
          </h2>
          <p className="terms__paragraph">
            5.1. You acknowledge and agree that all copyrights, trademarks, and
            service marks and rights in the name of or licensed to the Website,
            us or Our affiliated companies (if applicable) shall be and remain
            Our sole and complete property and You shall not acquire or claim
            any right, title, or interest of any nature in any such copyright,
            trademark, or service mark unless stated otherwise in these Terms of
            Service.
          </p>
          <p className="terms__paragraph">
            5.2. You will have a limited, non-exclusive, non-sublicensable and
            non-transferable license to use the Trading enhancement software
            with Your external cryptocurrency exchange and/or brokerage accounts
            through API integration. You do not have any rights to distribute,
            modify, sublicense, lease, lend, rent, decompile, reverse engineer,
            disassemble or otherwise distribute or amend the Trading enhancement
            software of the Website.
          </p>
          <p className="terms__paragraph">
            5.3. The license described under clause 5.2 above will automatically
            and immediately cease to exist upon:
          </p>
          <p className="terms__paragraph">
            5.3.1. Non-payment of any Website’s fees due pursuant to clause 4
            above; or
          </p>
          <p className="terms__paragraph">
            5.3.2. Termination of Your usage of the Trading enhancement software
            at Our or Your own discretion as described in these Terms of
            Service.
          </p>
        </li>

        {/* 6. Termination and Suspension */}
        <li className="terms__item">
          <h2 className="terms__question">6. Termination and Suspension</h2>
          <p className="terms__paragraph">
            6.1. You can terminate the use of Trading enhancement software at
            any time by serving a respective instruction to us through Your
            personal account on the Website. After serving the instruction, You
            shall settle all the balance due for the Website’s services as will
            be shown on "Payment" page. Upon the settlement of the payment, the
            Trading enhancement software will cease to have any access to Your
            connected cryptocurrency exchange and/or brokerage account within a
            reasonable period of time.
          </p>
          <p className="terms__paragraph">
            6.2. We can terminate and suspend Your account on the Website at any
            time with or without a notice if we, at Our sole discretion, have
            determined that You have violated these Terms of Service or
            otherwise became non-eligible for the usage of the Website and its
            Trading enhancement software.
          </p>
        </li>

        {/* 7.	Liability */}
        <li className="terms__item">
          <h2 className="terms__question">7. Liability</h2>
          <p className="terms__paragraph">
            7.1. We, to the extent permitted by applicable law, shall not be
            liable to You for any loss or damage, including any consequential
            losses or damages, directly or indirectly arising from the usage of
            the Website and its Trading enhancement software.
          </p>
          <p className="terms__paragraph">
            7.2. You hereby acknowledge and agree not to hold us responsible for
            and indemnify us and Our authorised representatives and affiliated
            entities against any claims, damages or losses arising out or in
            connection with these Terms of Service, including, but not limited
            to:
          </p>

          <ul className="terms__sked">
            <li className="terms__sked-item">
              Your use of the Website and its Trading enhancement software;
            </li>
            <li className="terms__sked-item">
              Your inability to the Website and its Trading enhancement
              software;
            </li>
            <li className="terms__sked-item">
              Viruses or other malicious software obtained either directly or
              indirectly from the Website or the Trading enhancement software;
            </li>
            <li className="terms__sked-item">
              Unauthorised access to Your account.
            </li>
          </ul>

          <p className="terms__paragraph">
            7.3. We in good faith believe that the information contained on the
            Website is accurate and up-to-date, however, We make no
            representations or warranties as to its accuracy or completeness,
            and We disclaim (and You agree to such disclaim) all liability for
            any direct, indirect, consequential, special, or exemplary losses or
            damages of any kind arising from or related to the use of the
            Website and any links to external websites which Our Website
            contains.
          </p>
        </li>

        {/* 8. Waiver of rights */}
        <li className="terms__item">
          <h2 className="terms__question">8. Waiver of rights</h2>
          <p className="terms__paragraph">
            8.1. A failure or delay by a party to exercise any right provided
            under these Terms of Service or by the applicable law shall not
            constitute a waiver of that or any other right, nor shall it prevent
            or restrict the further exercise of that other right. Any single or
            partial exercise of any such power, right or privilege shall not
            preclude other or further exercise thereof or of any other right,
            power or privilege.
          </p>
        </li>

        {/* 9. Severance */}
        <li className="terms__item">
          <h2 className="terms__question">9. Severance</h2>
          <p className="terms__paragraph">
            9.1. If any provision or part-provision of these Terms of Service is
            or becomes invalid, illegal or unenforceable, it shall be deemed
            deleted, but that shall not affect the validity and enforceability
            of the rest of these Terms of Service.
          </p>
        </li>

        {/* 10.	Notices */}
        <li className="terms__item">
          <h2 className="terms__question">10. Notices</h2>
          <p className="terms__paragraph">
            10.1. You agree and acknowledge that all communication between us
            concerning Your usage of the Website and its Trading enhancement
            software will be done electronically. We can communicate with You
            through any contact details You have left in Your account or by
            sending any notification or message to Your personal account on the
            Website.
          </p>
          <p className="terms__paragraph">
            10.2. The delivery of any message from Our side shall be effective
            as from the date We sent You any electronic message to any contact
            details You have left in Your account, or as from the date We sent
            any notification to Your personal account on the Website, or as from
            the date We posted any general notification on the Website’s main
            page. You are solely responsible for the regular monitoring of the
            Website for any communications and notification We might serve.
          </p>
        </li>

        {/* 11.	Assignment */}
        <li className="terms__item">
          <h2 className="terms__question">11. Assignment</h2>
          <p className="terms__paragraph">
            11.1. You cannot assign, transfer, hold on trust or otherwise
            delegate any of Your rights or obligations arising out from these
            Terms of Service to any third party without Our prior written
            consent. We reserve the right to transfer and assign any of Our
            rights and obligations to any third party without Your prior consent
            and in that case, We will serve a reasonable notice to You in
            accordance with clause 10 above.
          </p>
        </li>

        {/* 12.	Entire agreement */}
        <li className="terms__item">
          <h2 className="terms__question">12. Entire agreement</h2>
          <p className="terms__paragraph">
            12.1. These Terms of Service, the Privacy Policy, the Disclaimers
            and other supplementary documents available on the Website
            constitute the entire agreement and understanding between the You
            and us relating Your usage of the Website and its Trading
            enhancement software and supersedes all previous agreements (if any
            and whether in writing or not) between us in relation to such
            matters.
          </p>
        </li>

        {/* 13.	Governing law and dispute resolution */}
        <li className="terms__item">
          <h2 className="terms__question">
            13. Governing law and dispute resolution
          </h2>
          <p className="terms__paragraph">
            13.1. These Terms of Service shall be governed by and construed in
            accordance with the laws of the United States of America, the State
            of Wyoming. You and us both agree to notify each other of any
            arising dispute or claim withing 30 days of when it arises. We and
            You further agree to spend at least 60 days for full consultation,
            communication and mediation on such dispute before escalating the
            process for further actions. If We fail to come to compromise during
            those 60 days, the dispute shall be referred to and finally be
            settled by the respective courts of Wyoming.
          </p>
          <p className="terms__paragraph">
            13.2. You agree that You can bring a claim against us and/or the
            Website only in Your individual capacity and not as a plaintiff or
            class member in any purported class or representative proceeding.
          </p>
        </li>
      </ul>
      <p className="terms__post">
        These Terms of Service are only available in English. Any translated
        copies are not valid.
      </p>
    </main>
  );
}
