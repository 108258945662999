declare global {
  interface Window {
    _paq: any;
    fbq: any;
  }
}

export const trackEvent = (
  category: string,
  action: string,
  value?: number
) => {
  if (Number(process.env.REACT_APP_SEND_MATOMO_STATISTICS)) {
    window._paq.push(["trackEvent", category, action, value]);
  }
};

export const trackEventWithValue = (
  category: string,
  action: string,
  value: number
) => {
  if (Number(process.env.REACT_APP_SEND_MATOMO_STATISTICS)) {
    window._paq.push(["trackEvent", category, action, value]);
  }
};

export const trackFBEvent = () => {
  if (Number(process.env.REACT_APP_SEND_MATOMO_STATISTICS)) {
    window.fbq("track", "Lead");
  }
};
