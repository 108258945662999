import { useMemo, useState } from "react";
import { checkIfDesktop } from "../../utils/breakpoint";
import { faqScreenListData } from "./faq-data/faq-screen-list-data";
import FaqScreenItem from "./faq-screen-item";
import { debounce } from "../../utils/chart/chart";
import { RERENDER_DELAY } from "../../const";

function splitIntoColumns<T>(data: T[], numberOfColumns: number): T[][] {
  const columns: T[][] = Array.from({ length: numberOfColumns }, () => []);
  data.forEach((item, index) => {
    columns[index % numberOfColumns].push(item);
  });
  return columns;
}

function FaqScreenList() {
  const [isDesktop, setIsDesktop] = useState<boolean>(checkIfDesktop());

  const faqColumns = useMemo(() => {
    const numberOfColumns = isDesktop ? 2 : 1;

    return isDesktop
      ? splitIntoColumns(faqScreenListData, numberOfColumns)
      : [faqScreenListData];
  }, [isDesktop]);

  window.addEventListener(
    "resize",
    debounce(() => {
      setIsDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );

  return (
    <div className="faq__list-container">
      {faqColumns.map((column, columnIndex) => (
        <div className="faq__list-column" key={columnIndex}>
          {column.map((faq) => (
            <FaqScreenItem
              label={faq.label}
              icon={faq.icon}
              questions={faq.faqData}
            />
          ))}
        </div>
      ))}
    </div>
  );
}

export default FaqScreenList;
