import { useEffect, useState } from "react";

import { AuthStatus, RERENDER_DELAY } from "../../const";
import { BybitUpgradeUTASeoSetting } from "../../components/seo-setting/seo-setting";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setFirstScreenRendered } from "../../store/actions";
import { deleteSeoData } from "../../utils/general";
import { checkIfDesktop } from "../../utils/breakpoint";
import { debounce } from "../../utils/chart/chart";

export default function BybitChangeUtaScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { authStatus, isFirstScreenRendered } = useAppSelector(
    (state) => state
  );

  const [isDesktop, setDesktop] = useState<boolean>(checkIfDesktop());

  window.addEventListener(
    "resize",
    debounce(() => {
      setDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);
  //

  return (
    <main className="main setup">
      <BybitUpgradeUTASeoSetting />
      <div className="setup__wrapper">
        <h1 className="setup__heading">How to upgrade Standard Account to Unified Trading Account on Bybit?</h1>
        <p className="setup__intro">
          <b>The Unified Trading Account (UTA)</b> is an all-in-one account mode
            that gives traders the ability to utilize all features
            for trading without switching from one instrument account to another.
            Follow the steps below to change account types:
        </p>

        <ul className="setup__list">
          <li className="setup__item">
            <p className="setup__paragraph">
              1. Go to your {" "}
              <a
                  className="link"
                  href="https://www.bybit.com/user/assets/home/spot"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Spot
              </a>{" "}
              or {" "}
              <a
                  className="link"
                  href="https://www.bybit.com/user/assets/home/trading"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Derivatives
              </a>{" "}
              Account on Bybit and click on <b>"Upgrade Now"</b>.
            </p>
            <img
                className="setup__img"
                src={`${process.env.PUBLIC_URL}/img/screens/bybit-uta-setup/1.png`}
                alt="Bybit UTA setup"
                width={258}
                height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              2. Click on the <b>"Next"</b> button in the pop-up window.
            </p>
            <img
                className="setup__img setup__img--app8"
                src={`${process.env.PUBLIC_URL}/img/screens/bybit-uta-setup/2.png`}
                alt="Bybit UTA setup"
                width={180}
                height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              3. Acknowledge the Trading Rules and Terms of Service for the Unified Trading Account.
              Click on the <b>"Confirm Upgrade"</b> button.
            </p>
            <img
                className="setup__img setup__img--app8"
                src={`${process.env.PUBLIC_URL}/img/screens/bybit-uta-setup/3.png`}
                alt="Bybit UTA setup"
                width={180}
                height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              4. Your account is being upgraded.
              It takes about a few minutes to complete the upgrade.
              During this period, trading, deposits, withdrawals,
              transfers, and conversions are temporarily restricted.
            </p>
            <img
                className="setup__img setup__img--app9"
                src={`${process.env.PUBLIC_URL}/img/screens/bybit-uta-setup/4.png`}
                alt="Bybit UTA setup"
                width={160}
                height="auto"
            />
            <p className="setup__paragraph">
              You will receive a confirmation email after the upgrade is completed.
            </p>
          </li>

        </ul>
      </div>
    </main>
  );
}
