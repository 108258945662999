import { FaqData } from "../../../types/general";

export const FaqScreenConnectionData: FaqData[] = [
  {
    question: "How do I connect SMARD?",
    answer:
      "Find and select your exchange. Log into your personal exchange account and release the API keys. Copy and paste API keys into SMARD and enter the amount you want to trade.",
  },
  {
    question: "How much can I start with?",
    answer:
      "We provide access to the trading software starting at $1,000 (or the equivalent in your currency)",
  },
  {
    question: "I have the minimum amount on the exchange but can't connect.",
    answer: (
      <p className="faq-item__paragraph">
        Ensure that your funds are not in the Funding account, as this is the
        most common reason SMARD cannot detect your funds. Use the Spot wallet
        for spot strategies and the Futures wallet for futures strategies. If
        this does not resolve the issue, please contact our support team at{" "}
        <a className="link" href="mailto:info@smard.club">
          info@smard.club
        </a>
      </p>
    ),
  },
];
