export default function FaqPaymentsIcon(): JSX.Element {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.40052 19.5931H8.51997V21.8956H5.40052V19.5931Z"
        fill="#4076ED"
      />
      <path
        d="M32.0664 13.7228H2.93361C1.86569 13.7228 1 14.5872 1 15.6535V32.0694C1 33.1356 1.86569 34 2.93361 34H32.0664C33.1343 34 34 33.1356 34 32.0693V15.6535C34 14.5872 33.1343 13.7228 32.0664 13.7228ZM8.79834 29.8106H4.01244V27.8799H8.79834V29.8106ZM10.9539 21.9663C10.9539 22.8534 10.2311 23.5751 9.34263 23.5751H5.7524C4.86393 23.5751 4.14109 22.8534 4.14109 21.9663V19.1517C4.14109 18.2646 4.86393 17.5428 5.7524 17.5428H9.34256C10.231 17.5428 10.9539 18.2646 10.9539 19.1517L10.9539 21.9663ZM16.1947 29.8106H11.4088V27.8799H16.1947V29.8106ZM23.5912 29.8106H18.8053V27.8799H23.5912V29.8106ZM30.9876 29.8106H26.2017V27.8799H30.9876V29.8106ZM26.9572 5.68997L22.8265 1.56548C22.0714 0.811507 20.8471 0.811507 20.092 1.56548L9.84987 11.7921H21.195L26.9572 5.68997ZM26.8384 11.7921L29.8619 8.5903L28.3251 7.0558L23.8525 11.7921H26.8384ZM32.0664 11.7921C32.4817 11.7921 32.8817 11.8587 33.257 11.9802L31.2298 9.95612L29.4961 11.7922L32.0664 11.7921Z"
        fill="#4076ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 4.65283L9.84987 11.7922H17V4.65283ZM17 13.7229H2.93361C1.86569 13.7229 1 14.5873 1 15.6536V32.0694C1 33.1357 1.86569 34.0001 2.93361 34.0001H17V13.7229ZM4.01244 29.8107H8.79834V27.88H4.01244V29.8107ZM9.34263 23.5752C10.2311 23.5752 10.9539 22.8535 10.9539 21.9663L10.9539 19.1518C10.9539 18.2646 10.231 17.5429 9.34256 17.5429H5.7524C4.86393 17.5429 4.14109 18.2646 4.14109 19.1518V21.9663C4.14109 22.8535 4.86393 23.5752 5.7524 23.5752H9.34263ZM11.4088 29.8107H16.1947V27.88H11.4088V29.8107ZM8.51997 19.5931H5.40052V21.8957H8.51997V19.5931Z"
        fill="#60A5FA"
      />
    </svg>
  );
}
