import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { StateAction } from "./action-types";
import { ThunkApiConfigType } from "../types/api";
import { trackEvent } from "../utils/tracking";
import { redirectToRoute } from "./actions/user";
import { ApiRoute, AccountActionPath, AppRoute } from "../const";
import { CryptoWalletForServerType, MethodsOrderType } from "../types/payment";
import {
  loadNavAccounts,
  requireMessages,
  loadStrategyDetails,
  loadReferrals,
  loadClientSecret,
  addCard,
  loadPaymentMethods,
  setSendAddedCardStatus,
  loadLastPayments,
  deleteCard,
  setPaymentMethodsLoading,
  setLastPaymentsLoading,
  setAddCardLoading,
  setAccountsLoading,
  setReferralsLoading,
  setStrategyDetailsLoading,
  setRenewKeysOpened,
  setSendMethodsOrderStatus,
  setChargeMe,
  loadCryptoWallet,
  addDepositInLastPayments,
  setSendDepositInLastPaymentsStatus,
  loadFailedTransactions,
  setFailedTransactionsAgainLoading,
  setFailedTransactionsLoading,
  loadNotifications,
  setNotificationsLoading,
  resetNotificationsInMessages,
  setFixDivergenceLoading,
  loadCalcChart,
  setStopModalOpened,
  setDeleteCardModalOpened,
  setDeleteCardModalId,
  setTradableCoinsLoading,
  loadTradableCoins,
  setCoinLoading,
  loadCoin,
  setCalcChartLoading,
  loadUseAllAmount,
  setChangeAmountIsLoading,
  setChangeAmountStatus,
  setLoadUseAllAmountIsLoading,
  setUseAllAmountIsLoading,
  setUseAllAmountStatus,
  setIsLoadUserAmountChangesLoading,
  loadUserAmountChanges,
} from "./actions";

export const fetchNavAccounts = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(StateAction.Nav.LoadNavAccounts, async (_arg, { dispatch, extra: api }) => {
  dispatch(setAccountsLoading(true));
  try {
    const { data } = await api.get(ApiRoute.Accounts);
    dispatch(loadNavAccounts(data));
  } catch {}
  dispatch(setAccountsLoading(false));
});

export const checkMessagesAction = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Message.RequireMessages,
  async (_arg, { dispatch, extra: api }) => {
    const { data } = await api.get(ApiRoute.Messages);
    dispatch(requireMessages(data));
  }
);

export const fecthNotifications = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Message.LoadNotifications,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setNotificationsLoading(true));
    try {
      const { data } = await api.get(ApiRoute.Notifications);
      dispatch(loadNotifications(data));
    } catch {}
    dispatch(setNotificationsLoading(false));
  }
);

export const readNotifications = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Message.ResetNotificationsInMessages,
  async (_arg, { dispatch, extra: api }) => {
    try {
      await api.post(ApiRoute.NotificationsRead);
      dispatch(resetNotificationsInMessages(null));
    } catch {}
  }
);

export const stopStrategyAction = createAsyncThunk<
  void,
  string,
  ThunkApiConfigType
>(StateAction.Message.RequireMessages, async (id, { dispatch, extra: api }) => {
  try {
    await api.post(
      `${ApiRoute.AccountDetails}${id}${AccountActionPath.STOP}`,
      id
    );
    dispatch(setStopModalOpened(false));
    dispatch(redirectToRoute(AppRoute.Overview));
    trackEvent("Strategy", "Stopped");
  } catch {}
});

export const fetchStrategyDetails = createAsyncThunk<
  void,
  string,
  ThunkApiConfigType
>(
  StateAction.Strategy.LoadStrategyDetails,
  async (id, { dispatch, extra: api }) => {
    dispatch(setStrategyDetailsLoading(true));
    try {
      const { data } = await api.get(`${ApiRoute.AccountDetails}${id}/`);
      dispatch(loadStrategyDetails(data));
      if (data.renew_keys_form) {
        dispatch(setRenewKeysOpened(true));
      } else {
        // при открытии другого аккаунта закрыть renew-keys если было открыто
        dispatch(setRenewKeysOpened(false));
      }
    } catch (err) {
      if (err instanceof AxiosError && err.response?.status === 404) {
        dispatch(redirectToRoute(AppRoute.Manual));
      }
    }
    dispatch(setStrategyDetailsLoading(false));
    dispatch(setFixDivergenceLoading(false));
  }
);

export const fetchReferrals = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Referrals.LoadReferrals,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setReferralsLoading(true));
    try {
      const { data } = await api.get(ApiRoute.Referrals);
      dispatch(loadReferrals(data));
    } catch {}
    dispatch(setReferralsLoading(false));
  }
);

export const fetchClientSecret = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Payment.LoadClientSecret,

  async (_arg, { dispatch, extra: api }) => {
    try {
      const { data } = await api.get(ApiRoute.ClientSecret);
      dispatch(loadClientSecret(data.client_secret));
    } catch {
      dispatch(loadClientSecret(""));
    }
  }
);

export const fetchPaymentMethods = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Payment.LoadPaymentMethods,

  async (_arg, { dispatch, extra: api }) => {
    dispatch(setPaymentMethodsLoading(true));
    try {
      const { data } = await api.get(ApiRoute.PaymentMethods);
      dispatch(loadPaymentMethods(data));
    } catch {}
    dispatch(setPaymentMethodsLoading(false));
  }
);

export const fetchAddedCard = createAsyncThunk<void, any, ThunkApiConfigType>(
  StateAction.Payment.AddCard,
  async (setupIntent, { dispatch, extra: api }) => {
    try {
      const { data } = await api.post(ApiRoute.StripeCard, setupIntent);
      dispatch(
        setSendAddedCardStatus({
          isSucceed: true,
          message: "",
        })
      );
      dispatch(addCard(data));
      dispatch(fetchClientSecret());
    } catch (err) {
      if (err instanceof AxiosError && err.response?.data.detail) {
        dispatch(
          setSendAddedCardStatus({
            isSucceed: false,
            message: err.response?.data.detail,
          })
        );
      }

      if (err instanceof Error && !(err instanceof AxiosError)) {
        dispatch(
          setSendAddedCardStatus({
            isSucceed: false,
            message: err.message,
          })
        );
      }
    }
    dispatch(setAddCardLoading(false));
  }
);

export const fetchLastPayments = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Payment.LoadLastPayments,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setLastPaymentsLoading(true));
    try {
      const { data } = await api.get(ApiRoute.LastPayments);
      dispatch(loadLastPayments(data));
    } catch {}
    dispatch(setLastPaymentsLoading(false));
  }
);

export const deleteAddedCard = createAsyncThunk<void, any, ThunkApiConfigType>(
  StateAction.Payment.DeleteCard,
  async (code, { dispatch, extra: api }) => {
    try {
      await api.delete(`${ApiRoute.StripeCard}${code}/`);
      dispatch(deleteCard(code));
    } catch {}
    dispatch(setDeleteCardModalOpened(false));
    dispatch(setDeleteCardModalId(null));
  }
);

export const sendMethodsOrder = createAsyncThunk<
  void,
  MethodsOrderType,
  ThunkApiConfigType
>(
  StateAction.Payment.SetSendMethodsOrderStatus,
  async (order, { dispatch, extra: api }) => {
    try {
      await api.put(ApiRoute.PaymentMethodsOrder, order);
      dispatch(setSendMethodsOrderStatus(true));
    } catch (err) {
      dispatch(setSendMethodsOrderStatus(false));
    }
  }
);

export const chargeMe = createAsyncThunk<void, undefined, ThunkApiConfigType>(
  StateAction.Payment.SetChargeMe,
  async (_arg, { dispatch, extra: api }) => {
    try {
      await api.get(ApiRoute.ChargeMe);
      dispatch(setChargeMe(true));
      await dispatch(fetchLastPayments());
      await dispatch(fetchPaymentMethods());
    } catch (err) {
      dispatch(setChargeMe(false));
    }
  }
);

export const fetchCrytoWallet = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Payment.LoadCryptoWallet,
  async (_arg, { dispatch, extra: api }) => {
    try {
      const { data } = await api.get(ApiRoute.CryptoWallet);
      dispatch(loadCryptoWallet(data));
    } catch {}
  }
);

export const confirmCrytoWalletCharge = createAsyncThunk<
  void,
  CryptoWalletForServerType,
  ThunkApiConfigType
>(
  StateAction.Payment.LoadCryptoWallet,
  async (wallet, { dispatch, extra: api }) => {
    try {
      const { data } = await api.post(ApiRoute.LastPaymentsDeposit, wallet);
      dispatch(addDepositInLastPayments(data));
      dispatch(setSendDepositInLastPaymentsStatus(true));
    } catch {
      dispatch(setSendDepositInLastPaymentsStatus(false));
    }
  }
);

export const fetchFailedTransactions = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Payment.LoadFailedTransactions,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setFailedTransactionsLoading(true));

    try {
      const { data } = await api.get(ApiRoute.FailedTransactions);
      dispatch(loadFailedTransactions(data));
    } catch {}
    dispatch(setFailedTransactionsLoading(false));
  }
);

export const tryAgainFailedTransactions = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Payment.SetFailedTransactionsAgainLoading,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setFailedTransactionsAgainLoading(true));

    try {
      await api.post(ApiRoute.FailedTransactionsAgain);
      await dispatch(fetchFailedTransactions());
      await dispatch(fetchLastPayments());
    } catch {}

    dispatch(setFailedTransactionsAgainLoading(false));
  }
);

export const checkFixingDivergence = createAsyncThunk<
  void,
  string,
  ThunkApiConfigType
>(StateAction.Strategy.FixDivergence, async (id, { dispatch, extra: api }) => {
  dispatch(setFixDivergenceLoading(true));

  try {
    await api.post(
      `${ApiRoute.AccountDetails}${id}${AccountActionPath.FIX_DIVERGENCE}`
    );
    dispatch(fetchStrategyDetails(id));
  } catch {}
});

export const fetchCalcChart = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(StateAction.Chart.LoadCalcChart, async (_arg, { dispatch, extra: api }) => {
  dispatch(setCalcChartLoading(true));

  try {
    const { data } = await api.get(ApiRoute.MonthlyProfit);
    dispatch(loadCalcChart(data));
  } catch {}

  dispatch(setCalcChartLoading(false));
});

export const fetchTradableCoins = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Coins.LoadTradableCoins,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setTradableCoinsLoading(true));
    try {
      const { data } = await api.get(ApiRoute.TradableCoins);
      dispatch(loadTradableCoins(data));
    } catch {}
    dispatch(setTradableCoinsLoading(false));
  }
);

export const fetchCoin = createAsyncThunk<void, string, ThunkApiConfigType>(
  StateAction.Coins.LoadCoin,
  async (coinName, { dispatch, extra: api }) => {
    dispatch(setCoinLoading(true));
    try {
      const { data } = await api.get(`${ApiRoute.TradableCoins}${coinName}/`);
      dispatch(loadCoin(data));
    } catch (err) {
      if (err instanceof AxiosError && err.response?.status === 404) {
        dispatch(redirectToRoute(AppRoute.Manual));
      }
    }
    dispatch(setCoinLoading(false));
  }
);

export const setUseAllAmountAction = createAsyncThunk<
  void,
  { id: string; use_all: boolean },
  ThunkApiConfigType
>(
  StateAction.Strategy.SetUseAllAmount,
  async ({ id, use_all }, { dispatch, extra: api }) => {
    dispatch(setUseAllAmountIsLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${id}${AccountActionPath.USE_ALL}?use_all=${
          use_all ? "true" : "false"
        }`
      );
      dispatch(
        setUseAllAmountStatus({
          isSuccess: true,
          message: "",
        })
      );
    } catch (err) {
      if (err instanceof AxiosError && err.response?.data.detail) {
        dispatch(
          setUseAllAmountStatus({
            isSuccess: false,
            message: err.response?.data.detail,
          })
        );
      }
    } finally {
      dispatch(setUseAllAmountIsLoading(false));
    }
  }
);

export const changeAmountAction = createAsyncThunk<
  void,
  { id: string; amount_diff: number; new_amount: number },
  ThunkApiConfigType
>(
  StateAction.Strategy.SetChangeAmount,
  async ({ id, amount_diff, new_amount }, { dispatch, extra: api }) => {
    dispatch(setChangeAmountIsLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${id}${AccountActionPath.CHANGE_AMOUNT}?amount_diff=${amount_diff}&new_amount=${new_amount}`
      );
      dispatch(
        setChangeAmountStatus({
          isSuccess: true,
          message: "",
        })
      );
    } catch (err) {
      if (err instanceof AxiosError && err.response?.data.detail) {
        dispatch(
          setChangeAmountStatus({
            isSuccess: false,
            message: err.response?.data.detail,
          })
        );
      }
    } finally {
      dispatch(setChangeAmountIsLoading(false));
    }
  }
);

export const fetchUseAllAmount = createAsyncThunk<
  void,
  string,
  ThunkApiConfigType
>(
  StateAction.Strategy.LoadUseAllAmount,
  async (id, { dispatch, extra: api }) => {
    dispatch(setLoadUseAllAmountIsLoading(true));
    try {
      const { data } = await api.get(
        `${ApiRoute.AccountDetails}${id}${AccountActionPath.USE_ALL}`
      );
      dispatch(loadUseAllAmount(data.use_all_amount));
    } catch {
    } finally {
      dispatch(setLoadUseAllAmountIsLoading(false));
    }
  }
);

export const fetchUserAmountChanges = createAsyncThunk<
  void,
  string[],
  ThunkApiConfigType
>(
  StateAction.Strategy.LoadUserAmountChanges,
  async (ids, { dispatch, extra: api }) => {
    dispatch(setIsLoadUserAmountChangesLoading(true));
    try {
      const results = await Promise.all(
        ids.map(async (id) => {
          const { data } = await api.get(
            `${ApiRoute.AccountDetails}${id}${AccountActionPath.TRANSACTIONS_SUM}`
          );

          return { code: id, userAmountChanges: data };
        })
      );
      dispatch(loadUserAmountChanges(results));
    } catch (error) {
      console.error("Error fetching strategy transactions:", error);
    } finally {
      dispatch(setIsLoadUserAmountChangesLoading(false));
    }
  }
);
