export type PricingFeesDataType = {
  label: string;
  number: string;
  numberNote: string;
  note: string;
};

export const pricingFeesData: PricingFeesDataType[] = [
  {
    label: "Test period",
    number: "30",
    numberNote: "days before first payment",
    note: "No prepayment, card linking, or fund transfer required.",
  },
  {
    label: "Success fee",
    number: "10%",
    numberNote: "per month, billed monthly",
    note: "Profit first, then pay. We charge 10% after you profit only.",
  },
  {
    label: "Access fee",
    number: "$1",
    numberNote: "per month, billed monthly",
    note: "This fee applies only if no profit was made in the last 30 days.",
  },
];
