import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer } from "recharts";

import { checkResultColor } from "../../utils/chart/color";
import { displayChartNoGrid } from "./options/chart-grid";
import { displayChartGradient } from "./options/chart-gradient";
import { debounce, findMinMax, formatOverviewChartData } from "../../utils/chart/chart";
import { useState } from "react";
import { checkIfDesktop } from "../../utils/breakpoint";
import { RERENDER_DELAY } from "../../const";

type OverviewChartProps = {
  result: number;
  amounts: number[];
};

export default function OverviewChart({
  amounts,
  result,
}: OverviewChartProps): JSX.Element {
  const [isDesktop, setDesktop] = useState<boolean>(checkIfDesktop());

  const checkChartColor = () => checkResultColor(result);
  const chartType = `overview-${checkChartColor()}`;

  window.addEventListener(
    "resize",
    debounce(() => {
      setDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );
  return (
    <div className="chart chart--overview">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={formatOverviewChartData(amounts)}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          {displayChartGradient(chartType, checkChartColor)}
          {displayChartNoGrid()}

          <XAxis dataKey="timestamp" hide={true} />
          <YAxis dataKey="amount" hide={true} domain={isDesktop ? findMinMax(amounts) : [0, "auto"]} />
          <Area
            animationDuration={800}
            type="monotone"
            dataKey="amount"
            stroke="none"
            fillOpacity={0.6}
            fill={`url(#${chartType}-chart-gradient)`}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
