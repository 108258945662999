import { useDispatch } from "react-redux";
import ChangeAmountIcon from "../../../icons/change-amount-icon";
import { StrategyOverviewType } from "../../../types/details";
import { displayStrategyAmount } from "../../../utils/component/overview";
import { SetChangeBalanceFormOpened } from "../../../store/actions";
import { useAppSelector } from "../../../hooks";
import { StrategyStatus } from "../../../const";

type DetailsCurrentProps = {
  strategyStatus: string;
  isTabletAndMore: boolean;
  overview: StrategyOverviewType;
};

export default function DetailsCurrent({
  strategyStatus,
  isTabletAndMore,
  overview,
}: DetailsCurrentProps): JSX.Element {
  const { isChangeBalanceFormOpened } = useAppSelector((state) => state);
  const dispatch = useDispatch();
  const amount = overview.under_management;
  const currency = overview.currency;

  return currency && amount !== null ? (
    <div className="details-board__total details-board__total--current">
      <div className="details-board__amount-container">
        <p className="details-board__amount">
          {displayStrategyAmount(
            "current",
            strategyStatus,
            isTabletAndMore,
            amount,
            currency
          )}
        </p>
        {strategyStatus === StrategyStatus.Started ? (
          <div
            className="change-balance-change-icon-wrapper"
            onClick={() =>
              dispatch(SetChangeBalanceFormOpened(!isChangeBalanceFormOpened))
            }
          >
            <ChangeAmountIcon />
          </div>
        ) : null}
      </div>
      <span className="details-board__text" style={{ whiteSpace: "nowrap" }}>
        Current balance
      </span>
    </div>
  ) : (
    <></>
  );
}
