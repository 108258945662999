export default function FaqSecurityIcon(): JSX.Element {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.48906 31.2188H27.1406C29.0547 31.2188 30.6187 29.6547 30.6187 27.7406V20.6859H21.4859C20.6766 20.6859 19.9656 20.2484 19.5938 19.5922C19.4078 19.275 19.2984 18.8922 19.2984 18.4984V17.4047H3.1875V15.7969C3.1875 15.075 3.76719 14.4953 4.48906 14.4953H19.2984V12.3078H4.48906C2.56406 12.3078 1 13.8719 1 15.7969V27.7406C1.00289 28.6641 1.37177 29.5487 2.02578 30.2007C2.67979 30.8527 3.5656 31.2188 4.48906 31.2188ZM6.08594 24.4047H8.76563C9.36719 24.4047 9.85938 24.8969 9.85938 25.4984C9.85938 26.1 9.36719 26.5922 8.76563 26.5922H6.08594C5.48438 26.5922 4.99219 26.1 4.99219 25.4984C4.99219 24.8969 5.48438 24.4047 6.08594 24.4047Z"
        fill="#2563EB"
      />
      <path
        d="M32.7188 8.75312H31.6906V7.59375C31.6906 6.37541 31.2066 5.20697 30.3451 4.34548C29.4837 3.48398 28.3152 3 27.0969 3C25.8785 3 24.7101 3.48398 23.8486 4.34548C22.9871 5.20697 22.5031 6.37541 22.5031 7.59375V8.75312H21.4859C20.8734 8.75312 20.3922 9.23437 20.3922 9.84687V18.4984C20.3922 19.1 20.8734 19.5922 21.4859 19.5922H32.7188C33.3203 19.5922 33.8125 19.1 33.8125 18.4984V9.84687C33.8125 9.23437 33.3203 8.75312 32.7188 8.75312ZM27.0969 15.6437C26.2875 15.6437 25.6313 14.9766 25.6313 14.1672C25.6313 13.3578 26.2875 12.7016 27.0969 12.7016C27.9172 12.7016 28.5734 13.3578 28.5734 14.1672C28.5734 14.9766 27.9172 15.6437 27.0969 15.6437ZM29.5031 8.75312H24.6906V7.59375C24.6906 6.25938 25.7734 5.1875 27.0969 5.1875C28.4313 5.1875 29.5031 6.25938 29.5031 7.59375V8.75312Z"
        fill="#2563EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9062 12.3079H4.48906C2.56406 12.3079 1 13.8719 1 15.7969V27.7407C1.00289 28.6641 1.37177 29.5488 2.02578 30.2007C2.67979 30.8527 3.5656 31.2188 4.48906 31.2188H16.9062V17.4047H3.1875V15.7969C3.1875 15.075 3.76719 14.4954 4.48906 14.4954H16.9062V12.3079ZM8.76563 24.4047H6.08594C5.48438 24.4047 4.99219 24.8969 4.99219 25.4985C4.99219 26.1 5.48438 26.5922 6.08594 26.5922H8.76563C9.36719 26.5922 9.85938 26.1 9.85938 25.4985C9.85938 24.8969 9.36719 24.4047 8.76563 24.4047Z"
        fill="#60A5FA"
      />
    </svg>
  );
}
