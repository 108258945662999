import { Navigate, Route, Routes } from "react-router-dom";

import ScrollToTop from "../scroll-to-top/scroll-to-top";
import CheckMessages from "../checkMessages/check-messages";
import FirstMainRoute from "../first-main-route/first-main-route";
import Layout from "../layout/layout";
import LandingLayout from "../landing-layout/landing-layout";
import OverviewScreen from "../../pages/overview-screen/overview-screen";
import ManualScreen from "../../pages/manual-screen/manual-screen";
import DetailsScreen from "../../pages/details-screen/details-screen";
import PricingScreen from "../../pages/pricing-screen/pricing-screen";
import DisclaimersScreen from "../../pages/disclaimers-screen/disclaimers-screen";
import FaqScreen from "../../pages/faq-screen/faq-screen";
import TermsScreen from "../../pages/terms-screen/terms-screen";
import PolicyScreen from "../../pages/policy-screen/policy-screen";
import HistoryRouter from "../history-router/history-router";
import ReferralsScreen from "../../pages/referrals-screen/referrals-screen";
import PaymentsScreen from "../../pages/payments-screen/payments-screen";
import ServerErrorScreen from "../../pages/server-error-screen/server-error-screen";
import BinanceSetupScreen from "../../pages/binance-setup-screen/binance-setup-screen";
import OkxSetupScreen from "../../pages/okx-setup-screen/okx-setup-screen";
import PartnershipScreen from "../../pages/partnership-screen/partnership-screen";
import CoinsScreen from "../../pages/coins-screen/coins-screen";
import ModalsGroup from "../modals/modals-group";
import CoinScreen from "../../pages/coin-screen/coin-screen";
import AffiliatesScreen from "../../pages/affiliates-screen/affiliates-screen";
import OkxScreen from "../../pages/okx-screen/okx-screen";
import BitgetScreen from "../../pages/bitget-screen/bitget-screen";
import BybitScreen from "../../pages/bybit-screen/bybit-screen";
import OkxChangeModeScreen from "../../pages/okx-change-mode-screen/okx-change-mode-screen";
import BinanceScreen from "../../pages/binance-screen/binance-screen";
import BitgetSetupScreen from "../../pages/bitget-setup-screen/bitget-setup-screen";
import BybitSetupScreen from "../../pages/bybit-setup-screen/bybit-setup-screen";
import BitgetChangeModeScreen from "../../pages/bitget-change-mode-screen/bitget-change-mode-screen";
import CatchError from "../catch-error/catch-error";
import { AppRoute } from "../../const";
import { browserHistory } from "../../browser-history";
import { useAppSelector } from "../../hooks";
import BybitChangeUtaScreen from "../../pages/bybit-change-uta-screen/bybit-change-uta-screen";

export default function App(): JSX.Element {
  const { isFirstScreenRendered } = useAppSelector((state) => state);

  return (
    <HistoryRouter history={browserHistory}>
      <CatchError />
      <ScrollToTop />
      <CheckMessages />
      <Routes>
        {/* Проверка "/" в качестве 1й страницы во всех состояниях авторизации и для робота*/}
        {!isFirstScreenRendered && (
          <Route path={AppRoute.Manual} element={<FirstMainRoute />}></Route>
        )}

        <Route element={<LandingLayout />}>
          <Route path={AppRoute.Manual} element={<ManualScreen />}></Route>
          <Route
            path={AppRoute.Partnership}
            element={<PartnershipScreen />}
          ></Route>
          <Route path={AppRoute.Okx} element={<OkxScreen />}></Route>
          <Route path={AppRoute.Bitget} element={<BitgetScreen />}></Route>
          <Route path={AppRoute.Binance} element={<BinanceScreen />}></Route>
          <Route path={AppRoute.Bybit} element={<BybitScreen />}></Route>
          <Route path={AppRoute.Pricing} element={<PricingScreen />}></Route>
          <Route path={AppRoute.Faq} element={<FaqScreen />}></Route>
        </Route>

        <Route element={<Layout />}>
          <Route path={AppRoute.Overview} element={<OverviewScreen />}></Route>
          <Route
            path={`${AppRoute.Accounts}/:accountName`}
            element={<DetailsScreen />}
          ></Route>
          <Route path={AppRoute.Policy} element={<PolicyScreen />}></Route>
          <Route path={AppRoute.Terms} element={<TermsScreen />}></Route>
          <Route
            path={AppRoute.Disclaimers}
            element={<DisclaimersScreen />}
          ></Route>
          <Route path={AppRoute.Payments} element={<PaymentsScreen />}></Route>
          <Route
            path={AppRoute.Referrals}
            element={<ReferralsScreen />}
          ></Route>
          <Route
            path={AppRoute.Affiliates}
            element={<AffiliatesScreen />}
          ></Route>
          <Route
            path={AppRoute.BinanceSetup}
            element={<BinanceSetupScreen />}
          ></Route>
          <Route path={AppRoute.OkxSetup} element={<OkxSetupScreen />}></Route>
          <Route
            path={AppRoute.BitgetSetup}
            element={<BitgetSetupScreen />}
          ></Route>
          <Route
            path={AppRoute.OkxChangeMode}
            element={<OkxChangeModeScreen />}
          ></Route>
          <Route
            path={AppRoute.BybitSetup}
            element={<BybitSetupScreen />}
          ></Route>
          <Route
            path={AppRoute.BybitChangeUta}
            element={<BybitChangeUtaScreen />}
          ></Route>
          <Route
            path={AppRoute.BitgetChangeMode}
            element={<BitgetChangeModeScreen />}
          ></Route>
          <Route path={AppRoute.Error} element={<ServerErrorScreen />}></Route>
          <Route path={AppRoute.Coins} element={<CoinsScreen />}></Route>
          <Route
            path={`${AppRoute.Coins}:coinName`}
            element={<CoinScreen />}
          ></Route>
        </Route>

        <Route
          path={AppRoute.NotFound}
          element={<Navigate replace to={AppRoute.Manual} />}
        />
      </Routes>

      {/* В конец для перекрытия пердыдущих блоков */}
      <ModalsGroup />
    </HistoryRouter>
  );
}
