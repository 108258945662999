import { useEffect } from "react";

import PaymentsFailed from "../../components/payments-failed/payments-failed";
import PaymentsLast from "../../components/payments-last/payments-last";
import PaymentsWrapper from "../../components/payments-wrapper.tsx/payments-wrapper";
import { PaymentsSeoSetting } from "../../components/seo-setting/seo-setting";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { AuthStatus } from "../../const";
import { FailedTransactionsType } from "../../types/payment";
import { fetchFailedTransactions } from "../../store/api-actions";
import { setFirstScreenRendered } from "../../store/actions";
import { deleteSeoData } from "../../utils/general";
import { fetchPaymentInfo } from "../../store/api-actions/payments";

export default function PaymentsScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { authStatus, isPaymentMethodsLoading, isFirstScreenRendered } =
    useAppSelector((state) => state);
  const failedTransactions = useAppSelector(
    (state) => state.failedTransactions as FailedTransactionsType[]
  );

  useEffect(() => {
    dispatch(fetchPaymentInfo());

    if (authStatus === AuthStatus.Auth) {
      dispatch(fetchFailedTransactions());
    }
    // eslint-disable-next-line
  }, [authStatus]);

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);
  //

  return (
    <main className="main payments">
      <PaymentsSeoSetting />

      <h1 className="visually-hidden">Payments</h1>
      <PaymentsWrapper />

      {failedTransactions.length !== 0 && !isPaymentMethodsLoading && (
        <PaymentsFailed failedTransactions={failedTransactions} />
      )}
      <PaymentsLast />
    </main>
  );
}
