import { FaqData } from "../../../types/general";

export const FaqScreenAccountManagmentData: FaqData[] = [
  {
    question: "Can I trade on my account while it is being traded by SMARD?",
    answer:
      "Yes, you can continue to use your account to invest or trade; just do not touch the position that the bot has formed. If, at the time of trading, SMARD does not see the expected position, then it will pause and not continue until you recover the expected position in the assets.",
  },
  {
    question: "Why doesn’t the algorithm trade on spot strategy?",
    answer:
      "Spot strategies trade long only, so it either makes few or no trades when the market is falling or consolidating. But rest assured, the algorithm is constantly analyzing the market and looking for the right conditions to make a trade.",
  },
  {
    question: "How can I change the amount under SMARD management?",
    answer: (
      <p className="faq-item__paragraph">
        Click on the blue icon near your current balance and specify the amount
        you want to use. In addition you can check an option:{" "}
        <span style={{ fontStyle: "italic" }}>
          "Please always use all account available balance for trading"
        </span>{" "}
        and then SMARD will automatically use all available balance on your
        trading account.
      </p>
    ),
  },
  {
    question: "What happens after the strategy is stopped?",
    answer:
      "After the strategy is stopped, the algorithm will automatically close all open positions within 10-15 minutes.",
  },
];
