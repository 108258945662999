export default function FaqConnectionIcon(): JSX.Element {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3580_1215)">
        <path
          d="M9.46326 13.5806L2.43612 20.6061C-0.812041 23.8537 -0.812041 29.1377 2.43612 32.3853L2.61153 32.5607C4.18517 34.1335 6.27703 35 8.50254 35C10.728 35 12.8201 34.1335 14.3935 32.5607L21.1549 25.8009C20.6553 25.8731 20.1512 25.9093 19.6464 25.9093C18.2448 25.9093 16.8859 25.6332 15.6314 25.1071L11.3726 29.3649C10.6059 30.1313 9.58665 30.5535 8.50254 30.5535C7.41863 30.5535 6.39939 30.1315 5.63281 29.3652C4.86719 28.5999 4.44555 27.5809 4.44555 26.4957C4.44555 25.4108 4.86719 24.3915 5.63254 23.6262L12.4842 16.7764C13.2508 16.0097 14.2701 15.5878 15.3542 15.5878C16.4026 15.5878 17.3895 15.9833 18.1468 16.7024C18.1743 16.7248 18.2008 16.7486 18.2264 16.7742C18.6058 17.1534 19.11 17.3622 19.6464 17.3622C20.1826 17.3622 20.6871 17.1534 21.0662 16.7743L22.6003 15.2407C22.2649 14.7025 21.8691 14.2043 21.4206 13.756L21.2454 13.5808C19.6718 12.0075 17.5796 11.1412 15.3545 11.1412C13.1291 11.1412 11.0369 12.0075 9.46326 13.5806Z"
          fill="#60A5FA"
        />
        <path
          d="M32.5642 14.394C34.135 12.8237 35 10.7321 35 8.50432C35 6.27676 34.1348 4.18517 32.5642 2.61475L32.389 2.43954C30.8154 0.866592 28.7232 0 26.4978 0C24.2727 0 22.1805 0.866524 20.6069 2.43961L13.8454 9.19912C14.3451 9.12693 14.8493 9.09069 15.3541 9.0907C16.7555 9.0907 18.1145 9.36708 19.3689 9.8929L23.6278 5.63514C24.3944 4.86876 25.4137 4.44657 26.4978 4.44657C27.5819 4.44657 28.6012 4.86849 29.3678 5.63514C30.1334 6.40042 30.5548 7.41939 30.5548 8.50432C30.5548 9.58952 30.1334 10.6085 29.3678 11.3736L22.5162 18.2237C21.7496 18.9903 20.7303 19.4122 19.6462 19.4122C18.5981 19.4122 17.6112 19.0167 16.8539 18.2976C16.8261 18.2751 16.7994 18.2511 16.774 18.2258C16.3948 17.8466 15.8904 17.6378 15.3542 17.6378C14.8178 17.6378 14.3136 17.8466 13.9344 18.2258L12.4004 19.7596C12.7357 20.2977 13.1314 20.7957 13.5798 21.244L13.7553 21.4195C15.3289 22.9925 17.4207 23.8588 19.6462 23.8588C21.8716 23.8588 23.9638 22.9925 25.5371 21.4195L32.5642 14.394Z"
          fill="#2563EB"
        />
      </g>
      <defs>
        <clipPath id="clip0_3580_1215">
          <rect width="35" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
