export default function FaqMoneyIcon(): JSX.Element {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3580_1214)">
        <g clipPath="url(#clip1_3580_1214)">
          <path
            d="M22.1517 15.899C20.7076 15.14 19.1724 14.5617 17.6523 13.9617C16.7707 13.6148 15.9271 13.21 15.1822 12.6461C13.7154 11.5329 13.9966 9.72576 15.7143 9.01012C16.2007 8.80772 16.7099 8.74266 17.2267 8.71374C19.218 8.61254 21.1105 8.95952 22.9117 9.78358C23.8086 10.1956 24.105 10.0655 24.409 9.17638C24.7282 8.23665 24.9942 7.28247 25.2906 6.33552C25.4882 5.69939 25.245 5.28013 24.6142 5.01267C23.459 4.52835 22.2733 4.18138 21.0269 3.99343C19.4004 3.75489 19.4004 3.74766 19.3928 2.1935C19.3852 0.00321278 19.3852 0.00321274 17.0747 0.00321274C16.7403 0.00321274 16.4059 -0.00401592 16.0715 0.00321274C14.9922 0.0321274 14.8098 0.212844 14.7794 1.24654C14.7642 1.70918 14.7794 2.17181 14.7718 2.64167C14.7642 4.01512 14.7566 3.99343 13.3734 4.47052C10.0292 5.62711 7.96195 7.7957 7.74154 11.2655C7.54393 14.3376 9.2312 16.4123 11.8837 17.9231C13.5178 18.8555 15.3266 19.4049 17.0595 20.135C17.7359 20.4169 18.382 20.7422 18.9444 21.1904C20.6088 22.4988 20.3048 24.6746 18.3288 25.4987C17.2723 25.9396 16.1551 26.0481 15.0074 25.9107C13.2366 25.7011 11.5417 25.2601 9.94563 24.4722C9.01079 24.0096 8.73718 24.1325 8.41796 25.0939C8.14435 25.9252 7.90115 26.7637 7.65794 27.6022C7.33112 28.7299 7.45273 28.9974 8.58517 29.525C10.0292 30.1901 11.5721 30.5298 13.1454 30.7684C14.3766 30.9563 14.4146 31.0069 14.4298 32.2213C14.4374 32.7707 14.4374 33.3273 14.445 33.8767C14.4526 34.5707 14.8022 34.9755 15.5546 34.9899C16.4059 35.0044 17.2647 35.0044 18.1159 34.9827C18.8152 34.9682 19.1724 34.6068 19.1724 33.9345C19.1724 33.1827 19.2104 32.4237 19.18 31.672C19.142 30.9057 19.4916 30.5154 20.2668 30.313C22.0529 29.8503 23.573 28.9395 24.7434 27.5805C27.9963 23.8216 26.7575 18.3206 22.1517 15.899Z"
            fill="#60A5FA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2219 34.9991C17.5761 34.9971 17.9301 34.9918 18.2828 34.9827C18.9684 34.9682 19.3186 34.6068 19.3186 33.9345C19.3186 33.6663 19.3234 33.3972 19.3281 33.1277C19.3367 32.6421 19.3452 32.1555 19.3261 31.672C19.2888 30.9057 19.6316 30.5154 20.3916 30.313C22.1428 29.8503 23.6331 28.9395 24.7806 27.5805C27.9699 23.8216 26.7553 18.3206 22.2396 15.899C20.9842 15.226 19.6584 14.695 18.3351 14.165C18.166 14.0974 17.9971 14.0297 17.8283 13.9617C17.6238 13.8797 17.4214 13.7943 17.2219 13.7044V20.1242C17.2303 20.1278 17.2387 20.1314 17.2471 20.135C17.9103 20.4169 18.5436 20.7422 19.0951 21.1904C20.727 22.4988 20.4289 24.6746 18.4915 25.4987C18.0772 25.6751 17.6533 25.7982 17.2219 25.8738V34.9991ZM17.2219 0.00317383V8.72643C17.2848 8.72151 17.3478 8.71734 17.411 8.71374C19.3633 8.61254 21.2188 8.95951 22.9848 9.78358C23.8641 10.1956 24.1547 10.0655 24.4528 9.17637C24.6526 8.57631 24.8312 7.97035 25.0099 7.36414C25.111 7.021 25.2121 6.67778 25.3172 6.33551C25.5109 5.69939 25.2725 5.28013 24.654 5.01267C23.5213 4.52835 22.3589 4.18137 21.1368 3.99343C19.5422 3.75489 19.5422 3.74765 19.5347 2.19359L19.5347 2.19349C19.531 1.11065 19.5292 0.563151 19.2545 0.286325C18.9736 0.0032084 18.4073 0.00320839 17.262 0.00320839C17.2486 0.00320839 17.2352 0.00319638 17.2219 0.00317383Z"
            fill="#2563EB"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3580_1214">
          <rect width="35" height="35" fill="white" />
        </clipPath>
        <clipPath id="clip1_3580_1214">
          <rect width="35" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
