// Единственная случай, где сработала обертка с Customized
export function displayChartGradient(
  type: string,
  checkColor: () => string
): JSX.Element {
  return (
    <defs>
      <linearGradient
        id={`${type}-chart-gradient`}
        x1="0"
        y1="0"
        x2="0"
        y2={type.includes("overview") ? 1.5 : 1}
      >
        <stop offset="10%" stopColor={checkColor()} />
        <stop offset="90%" stopColor="#FFFFFF" />
      </linearGradient>
    </defs>
  );
}
