import { StrategyStatus } from "../../../const";
import { formatStrategy } from "../../../utils/amount";
import { showDateWithShortYear } from "../../../utils/datetime";
import { StrategyType } from "../../../types/strategy";
import {
  addBoardColorSign,
  displayStrategyAmount,
} from "../../../utils/component/overview";

type OverviewFromStartProps = {
  strategyStatus: string;
  isTabletAndMore: boolean;
  overview: StrategyType;
};

export default function OverviewFromStart({
  strategyStatus,
  isTabletAndMore,
  overview,
}: OverviewFromStartProps): JSX.Element {
  const amount =
    strategyStatus === StrategyStatus.NotStarted
      ? overview.annualized_return
      : overview.profit_from_start;
  const percent =
    strategyStatus === StrategyStatus.NotStarted
      ? overview.annualized_return
      : overview.profit_from_start_percent;
  const timestamp = overview.start_timestamp;
  const currency = overview.currency;
  const numberForChecking =
    strategyStatus === StrategyStatus.NotStarted ? percent : amount;

  const chooseLabel = (value: number) => {
    if (value >= 0) {
      return "Profit";
    } else return "Loss";
  };

  const label =
    strategyStatus === StrategyStatus.NotStarted
      ? "Annualized return"
      : amount || amount === 0
      ? chooseLabel(formatStrategy(amount).amount)
      : "None";

  return amount !== null &&
    timestamp &&
    percent !== null &&
    numberForChecking !== null ? (
    <div
      className={`overview-board__total overview-board__total--from-start ${
        formatStrategy(numberForChecking).amount >= 0
          ? "overview-board__total--up"
          : "overview-board__total--down"
      }`}
    >
      <p className="overview-board__amount">
        {strategyStatus === StrategyStatus.Started ||
        strategyStatus === StrategyStatus.Demo ||
        strategyStatus === StrategyStatus.Stopping
          ? null
          : addBoardColorSign(
              isTabletAndMore,
              strategyStatus,
              formatStrategy(amount).amount,
              formatStrategy(percent).amount
            )}
        <span className="overview-board__number">
          {displayStrategyAmount(
            "from-start",
            strategyStatus,
            isTabletAndMore,
            amount,
            currency,
            percent
          )}
        </span>
      </p>
      <span className="overview-board__text">{label}</span>
    </div>
  ) : (
    <></>
  );
}
