import { FaqData } from "../../types/general";
import FaqItem from "./faq-item";

type FaqScreenItemProps = {
  label: string;
  icon: JSX.Element;
  questions: FaqData[];
};

function FaqScreenItem({ icon, label, questions }: FaqScreenItemProps) {
  return (
    <div className="faq-item__container">
      <div className="faq-item__container-header">
        {icon}
        <p className="faq__inner-heading">{label}</p>
      </div>
      <div className="faq-item__container-questions-wrapper">
        {questions.map((question) => {
          return (
            <FaqItem
              key={question.question}
              question={question.question}
              answer={<p className="faq-item__paragraph">{question.answer}</p>}
            />
          );
        })}
      </div>
    </div>
  );
}

export default FaqScreenItem;
