import { useEffect } from "react";

import { FaqSeoSetting } from "../../components/seo-setting/seo-setting";
import { useAppDispatch, useAppSelector } from "../../hooks/index";
import { AuthStatus } from "../../const";
import { setFirstScreenRendered } from "../../store/actions";
import { deleteSeoData } from "../../utils/general";
import FaqScreenList from "../../components/faq/faq-screen-list";

export default function FaqScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { authStatus, isFirstScreenRendered } = useAppSelector(
    (state) => state
  );

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);
  //

  return (
    <main className="main main--landing landing">
      <div className="landing__wrapper">
        <FaqSeoSetting />
        <h1 className="faq__heading">Frequently Asked Questions</h1>
        <ul className="faq__list">
          <FaqScreenList />
        </ul>
      </div>
    </main>
  );
}
