import { SyntheticEvent, useRef, useState } from "react";
import { Link } from "react-router-dom";

import OverviewAlert from "./elements/overview-alert";
import OverviewStarted from "./status/overview-started";
import OverviewNotStarted from "./status/overview-not-started";
import OverviewComingSoon from "./status/overview-comming-soon";
import { StrategyType } from "../../types/strategy";
import { debounce } from "../../utils/chart/chart";
import { setZIndex } from "../../utils/component/overview";
import { checkIfTabletAndMore } from "../../utils/breakpoint";
import { AppRoute, StrategyStatus, RERENDER_DELAY } from "../../const";
import { UserAmountChangesType } from "../../types/details";

type OverviewBoardProps = {
  overview: StrategyType;
  index: number;
  strategiesLength: number;
  userAmountChanges: UserAmountChangesType | null;
};

export default function OverviewBoard({
  overview,
  index,
  strategiesLength,
  userAmountChanges,
}: OverviewBoardProps): JSX.Element {
  const status = overview.status;

  const [isTabletAndMore, setTabletAndMore] = useState<boolean>(
    checkIfTabletAndMore()
  );
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const hintStatusRef = useRef<HTMLDivElement | null>(null);
  const hintButtonRef = useRef<HTMLDivElement | null>(null);
  const multipleRef = useRef({ buttonRef, hintButtonRef });

  const isNavigateAble =
    status === StrategyStatus.Started ||
    status === StrategyStatus.NotStarted ||
    status === StrategyStatus.EmptyData ||
    status === StrategyStatus.Demo ||
    status === StrategyStatus.Stopping;

  const handleBoardClick = (evt: SyntheticEvent) => {
    if (
      buttonRef?.current?.contains(evt.target as Element) ||
      hintStatusRef?.current?.contains(evt.target as Element) ||
      hintButtonRef?.current?.contains(evt.target as Element)
    ) {
      evt.preventDefault();
    }
  };

  const handleLinkRoute = () => {
    if (isNavigateAble) {
      return `${AppRoute.Accounts}/${overview.code}/`;
    }
    return "";
  };

  window.addEventListener(
    "resize",
    debounce(() => {
      setTabletAndMore(checkIfTabletAndMore());
    }, RERENDER_DELAY)
  );

  const renderBlock = () => {
    switch (status) {
      case StrategyStatus.Started:
      case StrategyStatus.Demo:
      case StrategyStatus.Stopping:
        return (
          <OverviewStarted
            userAmountChanges={userAmountChanges}
            overview={overview}
            isTabletAndMore={isTabletAndMore}
            index={index}
            ref={multipleRef}
            isNavigateAble={isNavigateAble}
          />
        );

      case StrategyStatus.NotStarted:
        return (
          <OverviewNotStarted
            overview={overview}
            isTabletAndMore={isTabletAndMore}
            index={index}
            ref={multipleRef}
            isNavigateAble={isNavigateAble}
          />
        );

      case StrategyStatus.ComingSoon:
      case StrategyStatus.EmptyData:
        return (
          <OverviewComingSoon
            overview={overview}
            index={index}
            ref={multipleRef}
            isNavigateAble={isNavigateAble}
          />
        );

      default:
        return (
          <OverviewComingSoon
            overview={overview}
            index={index}
            ref={multipleRef}
            isNavigateAble={isNavigateAble}
          />
        );
    }
  };

  return (
    <section className="overview-board">
      <h2 className="visually-hidden">Strategy overview board</h2>
      <Link
        to={handleLinkRoute()}
        className={`overview-board__link ${
          isNavigateAble ? "" : "overview-board__link--disabled"
        }`}
        style={{ zIndex: setZIndex(index, strategiesLength) }}
        onClick={handleBoardClick}
      >
        <div className="overview-board__logo-wrapper">
          <img
            className={`overview-board__logo overview-board__logo--${overview.code}`}
            src={`/frontend/dist/img/strategy/${overview.code}.svg`}
            alt={`Logo ${overview.code}`}
            width="60"
            height="20"
          />
        </div>

        <div className="overview-board__block">{renderBlock()}</div>
        {overview.alert && (
          <OverviewAlert
            alert={overview.alert}
            status={status}
            index={index}
            ref={hintStatusRef}
          />
        )}
      </Link>
    </section>
  );
}
