import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../button/button";
import CalcBlock from "../calc-block/calc-block";
import CalcCanvasSkeleton from "../skeletons/calc-canvas-skeleton";
import LightDarkBlueDiskIcon from "../../icons/decor/light-dark-blue-disk-icon";
import { AppRoute, AuthStatus, RERENDER_DELAY } from "../../const";
import { trackEvent } from "../../utils/tracking";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { debounce } from "../../utils/chart/chart";
import { setAuthModalOpened } from "../../store/actions";
import { formatedMinNotional } from "../../utils/env";
import { checkIfTabletAndMore } from "../../utils/breakpoint";
import { displayResultsPercent } from "../../utils/results";

export default function Promo(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    authStatus,
    calcChart,
    isCalcChartLoading,
    isOkxDemoLoading,
    isBitgetFuturesDemoLoading,
    isBinanceSpotDemoLoading,
    isBinanceFuturesDemoLoading,
    bybitDemo,
  } = useAppSelector((state) => state);
  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const isLoading =
    isCalcChartLoading ||
    isOkxDemoLoading ||
    isBitgetFuturesDemoLoading ||
    isBinanceSpotDemoLoading ||
    isBinanceFuturesDemoLoading;

  const [isTabletAndMore, setTabletAndMore] = useState<boolean>(
    checkIfTabletAndMore()
  );

  const handleDemoClick = () => {
    navigate(AppRoute.Overview);
  };

  const handleStartedClick = () => {
    if (authStatus === AuthStatus.Auth) {
      navigate(AppRoute.Overview);
    } else {
      dispatch(setAuthModalOpened(true));
    }
    trackEvent("HIW", "Clicked");
  };

  window.addEventListener(
    "resize",
    debounce(() => {
      setTabletAndMore(checkIfTabletAndMore());
    }, RERENDER_DELAY)
  );

  return (
    <section className="landing__item promo">
      <div className="promo__heading">
        <h1 className="landing__heading">
          <span className="landing__heading-large">
            Automated <span className="visually-hidden">crypto </span>trading{" "}
            <span className="landing__heading-text-tool" />
            <span className="visually-hidden">bot</span>
          </span>{" "}
          for <span className="landing__heading-large">maximum results</span>{" "}
          in&nbsp;<span className="landing__heading-large">any market</span>
        </h1>
      </div>
      <div className="promo__description">
        <ul className="promo__list">
          {bybitDemo?.strategy_results.annualized_return ? (
            <li className="promo__item">
              <span className="promo__icon">
                <LightDarkBlueDiskIcon />
              </span>
              Historically up to{" "}
              {displayResultsPercent(
                Math.round(bybitDemo?.strategy_results.annualized_return)
              )}{" "}
              APY
            </li>
          ) : null}
          <li className="promo__item">
            <span className="promo__icon">
              <LightDarkBlueDiskIcon />
            </span>
            Overtakes BTC and ETH in the long run
          </li>
          <li className="promo__item">
            <span className="promo__icon">
              <LightDarkBlueDiskIcon />
            </span>
            10% monthly service fee charged on profits&nbsp;only
          </li>
          <li className="promo__item">
            <span className="promo__icon">
              <LightDarkBlueDiskIcon />
            </span>
            No upfront payments
          </li>
          <li className="promo__item">
            <span className="promo__icon">
              <LightDarkBlueDiskIcon />
            </span>
            {`Start from $${formatedMinNotional}`}
          </li>
        </ul>
        <div className="promo__buttons-wrapper">
          {isNoAuth && (
            <Button
              classElement="promo__btn"
              pattern="blue-white"
              text="View free demo"
              icon="eye"
              type="button"
              size="big"
              handleClick={handleDemoClick}
            />
          )}
          <Button
            classElement="promo__btn"
            pattern="white-blue"
            text="Get started"
            icon="arrow-right"
            type="button"
            handleClick={handleStartedClick}
          />
        </div>
      </div>

      {isTabletAndMore && (
        <div className="promo__chart">
          {isLoading ? (
            <CalcCanvasSkeleton />
          ) : (
            calcChart && <CalcBlock chart={calcChart} />
          )}
        </div>
      )}
    </section>
  );
}
