import SkeletonChartIcon from "../../icons/decor/chart-skeleton-icon";

export default function StrategySkeleton(): JSX.Element {
  const renderAmountBlock = (isInitial?: boolean) => (
    <div
      className={`sk-strategy__amount-wrapper ${
        isInitial ? "sk-strategy__amount-wrapper--initial" : ""
      }`}
    >
      <div className="sk-strategy__amount">
        <div className="sk-strategy__amount-inner"></div>
      </div>
      <div className="sk-strategy__note">
        <div className="sk-strategy__note-inner"></div>
      </div>
    </div>
  );

  return (
    <div className="sk-strategy animate-pulse">
      <div className="sk-strategy__img-wrapper">
        <div className="sk-strategy__img"></div>
      </div>

      <div className="sk-strategy__big-wrapper">
        <div className="sk-strategy__small-wrapper">
          {renderAmountBlock(true)}
          {renderAmountBlock()}
        </div>
        <div className="sk-strategy__small-wrapper">
          {renderAmountBlock()}
          {renderAmountBlock()}
          <div className="sk-strategy__hide-for-mobile">
            {renderAmountBlock()}
          </div>
        </div>
        <div className="sk-strategy__btn"></div>
      </div>
      <div className="sk-strategy__chart">
        <SkeletonChartIcon isForEmptyChart />
      </div>
    </div>
  );
}
