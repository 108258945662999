import EqualIcon from "../icons/sign/equal-icon";
import MinusIcon from "../icons/sign/minus-icon";
import PlusIcon from "../icons/sign/plus-icon";

type BoardSignProps = {
  sign: string;
  isColored?: boolean;
  isYourChanges?: boolean;
};

export default function BoardSign({
  sign,
  isColored,
  isYourChanges = false,
}: BoardSignProps): JSX.Element {
  const displayColoredIcon = () => {
    switch (sign) {
      case "plus":
        return (
          <span className="board-sign board-sign--colored-plus">
            <PlusIcon />
          </span>
        );
      case "minus":
        return (
          <span className="board-sign board-sign--colored-minus">
            <MinusIcon />
          </span>
        );
      default:
        return <></>;
    }
  };

  const displayIcon = () => {
    switch (sign) {
      case "plus":
        return (
          <span
            className="board-sign board-sign--plus"
            style={{ color: isYourChanges ? "#828282" : "#1a1a1a" }}
          >
            <PlusIcon />
          </span>
        );
      case "minus":
        return (
          <span
            className="board-sign board-sign--minus"
            style={{ color: isYourChanges ? "#828282" : "#1a1a1a" }}
          >
            <MinusIcon />
          </span>
        );
      case "equal":
        return (
          <span className="board-sign board-sign--equal">
            <EqualIcon />
          </span>
        );
      default:
        return <></>;
    }
  };

  return isColored ? displayColoredIcon() : displayIcon();
}
