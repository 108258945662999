import { useEffect } from "react";

import { DisclaimersSeoSetting } from "../../components/seo-setting/seo-setting";
import { AuthStatus } from "../../const";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setFirstScreenRendered } from "../../store/actions";
import { deleteSeoData } from "../../utils/general";

export default function DisclaimersScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { authStatus, isFirstScreenRendered } = useAppSelector(
    (state) => state
  );

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);
  //

  return (
    <main className="main disclaimers">
      <DisclaimersSeoSetting />
      <h1 className="disclaimers__heading">
        Terms of Website Use and Risks Disclaimer
      </h1>
      <ul className="disclaimers__list">
        <li className="disclaimers__item">
          <h2 className="disclaimers__question">General</h2>
          <p className="disclaimers__paragraph">
            You hereby agree and acknowledge that You have read and accept the
            information contained on this page and agree to be bound by its
            terms by accessing the Website{" "}
            <a
              className="link"
              href="https://smard.club"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://smard.club
            </a>{" "}
            and any webpages thereof (collectively "Website"). "You" or "Your"
            refer to You personally and any other person who accesses on Your
            behalf, and/or on whose behalf You are accessing, the Website. "We"
            refer to the Website operator, Smard LLC registered under the laws
            of the United States of America, the state of Wyoming, registration
            number 2022-001172916, and You hereby agree and acknowledge that we
            can unilaterally change this page and/or any documents contained on
            this page without any formal notice to You.
          </p>
          <p className="disclaimers__paragraph">
            For the purposes of usage of the Website and its services we
            consider that You have read the information, documents and materials
            contained on the Website and that You are familiar with all
            disclosed risks and conditions while You continuously agree and
            acknowledge that You are accepting the risks, terms and conditions
            contained and disclosed on the Website each time You access and use
            the Website and its services and/or features.
          </p>
          <p className="disclaimers__paragraph">
            The Website is not intended to be distributed to, or accessed or
            used by, any person in any jurisdiction where the distribution of
            the information contained on the Website is or would be contrary to
            the laws or regulations of such jurisdiction or would subject us
            and/or the Website to any form of registration and/or sanction
            compliance requirement of such jurisdiction, including, but not
            limited to, Afghanistan, Cuba, Iran, North Korea and Syria.
          </p>
        </li>

        <li className="disclaimers__item">
          <h2 className="disclaimers__question">No offer and no advice</h2>
          <p className="disclaimers__paragraph">
            The information and features of the Website are provided only for
            information purposes and in no way constitute any form of any
            solicitation to offer, buy, sell, recommend, advice to buy and/or
            sell or otherwise acquire or dispose of, any interest or any share
            in any financial and/or digital assets, or any offer, advice or
            recommendation to purchase or follow any investment strategy.
          </p>
          <p className="disclaimers__paragraph">
            Not we nor the Website are licensed and/or regulated and/or
            registered in any jurisdiction as a financial and/or investment
            advisor, broker, portfolio manager or tax advisor. The information
            contained on the Website does not constitute any form of legal,
            investment, tax or other advice.
          </p>
          <p className="disclaimers__paragraph">
            For the avoidance of doubt, the Website offers only its Trading
            enhancement software described under its Terms of Service. The
            Trading enhancement software is designed to improve Your trading
            experience. The decision to use the Trading enhancement software is
            done at Your own risk and discretion. The Trading enhancement
            software must not in any way be interpreted or considered as any
            form of robo-advisor software.
          </p>
        </li>

        <li className="disclaimers__item">
          <h2 className="disclaimers__question">
            General risks of trading and investments
          </h2>
          <p className="disclaimers__paragraph">
            Investments and trading in digital and/or financial assets have high
            risk of losing the principal amount You have invested. You shall
            familiarise Yourself with a digital and/or financial asset You are
            planning to invest into or take an independent financial advice if
            You have any doubts in choosing Your investment strategy.
          </p>
          <p className="disclaimers__paragraph">
            The value of digital and/or financial assets may fluctuate greatly,
            and the past performance and history of a particular digital and/or
            financial assets cannot indicate its likely future performance.
            Trading in digital and/or financial assets can be not suitable for
            You.
          </p>
          <p className="disclaimers__paragraph">
            You shall seek a professional and independent advice specific to
            Your circumstances and financial possibilities before accessing the
            Website and/or using its features and services. The Website and its
            features might be not suitable for You and You are solely
            responsible for any actions and decisions made on and with the
            Website and its features. We are not responsible for any liability
            arising our or in connection with Your usage of the Website and we
            do not guarantee the performance of any trade or investment, nor the
            return of any principal amount You have invested or the achievement
            of any rate of return.
          </p>
        </li>

        <li className="disclaimers__item">
          <h2 className="disclaimers__question">Exclusion of warranties</h2>
          <p className="disclaimers__paragraph">
            The Website is provided to You "as is" and "as available" and we
            hereby disclaim any and all implied warranting on availability of
            the Website and its content. We, our directors, employees,
            affiliates, officers and agents expressly disclaim any and all
            representations or warranties of conditions, either express or
            implied, including, but not limited to, warranties on
            merchantability, fitness for a particular purpose, title, security,
            reliability, error-free or uninterrupted operation and/or
            availability of the Website and its features. You have a right to
            discontinue the usage of the Website and its features at any time
            and You hereby agree and acknowledge that it is Your only remedy in
            connection with the Website which is considered by You as fair and
            reasonable.
          </p>
          <p className="disclaimers__paragraph">
            We in good faith believe that the information contained on the
            Website is accurate and up-to-date, however, we make no
            representations or warranties as to its accuracy or completeness,
            and we disclaim all liability for any direct, indirect,
            consequential, special, or exemplary losses or damages of any kind
            arising from or related to the use of the Website and any links to
            external websites which our Website contains.
          </p>
          <p className="disclaimers__paragraph">
            The information contained on the Website include some content which
            is made available through the hyperlinks to external websites,
            platform or other sources. Such information is provided by unrelated
            third-party providers we are selecting with due care and diligence
            (in good faith), however, we are not reviewing such information for
            accuracy and therefore cannot accept any liability for the accuracy,
            completeness and/or reliability of such information. We expressly
            disclaim all responsibility for any information contained on the
            Website and any non-performance of unrelated third-party providers
            who provide such information.
          </p>
          <p className="disclaimers__paragraph">
            We do not provide any technical support on the Website while always
            reserving the right to modify, update and/or adapt the Website and
            its content at our sole discretion without any formal notice to You.
            The Website can be inaccessible due to such updates and we do not
            accept any liability for any damages or losses suffered during or
            after such updates.
          </p>
          <p className="disclaimers__paragraph">
            We further do not guarantee that any downloadable content on the
            Website if free from any viruses or malware. You are solely
            responsible for the security, protection and safety of Your device
            and data while using the Website and its features.
          </p>
        </li>

        <li className="disclaimers__item">
          <h2 className="disclaimers__question">Limitation of liability</h2>
          <p className="disclaimers__paragraph">
            You acknowledge and agree that we, our director, affiliates,
            employees and officers, agents and authorised representatives are
            not and will not be liable to You for any and all direct, indirect,
            incidental, special, consequential or exemplary damages that You may
            suffer in connection with Your use of the Website.
          </p>
          <p className="disclaimers__paragraph">
            You agree and warrant to keep Us, our employees, directors,
            officers, authorised representatives harmless from any and all
            liability and losses You might suffer due to direct or indirect
            usage of the Website and its features.
          </p>
          <p className="disclaimers__paragraph">
            You are solely responsible for any actions done with the Website and
            agree and acknowledge that all investments and trading in digital
            and/or financial assets is and will be done at Your own risk and
            expertise.
          </p>
        </li>
      </ul>
    </main>
  );
}
